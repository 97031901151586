import axios from "axios";
import { common_api, crm_api } from "../../../Config";

export const businessData = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/admin/bussinessdata.json"
  );
};

export const salesSummaryData = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/admin/salessummarydata.json"
  );
};
export const salesChannelsData = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/admin/saleschannels.json"
  );
};

export const getClaims = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/admin/claims.json"
  );
};



export const getPremium = async (filters) => {
  let res = await axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/home", filters
  );
  return res;
};

export const getSalesData = (payload) => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/sales/info/${payload}`
  );
};

export const getSalesDataPost = (payload) => {
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/sales/info",
    payload
  );
};

export const getPendingCases = (payload) => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/penidngcases/${payload}`
  );
};

export const postPendingCases = (payload) => {
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/penidngcases",
    payload
  );
};

export const getSelfInspecData = (payload) => {
  return axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/transactionpannel/${payload}`
  );
};
// https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/transactionpannel/{empid}

export const selfInspecDataByFilter = (payload) => {
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/transactionpannel",
    payload
  );
};

export const selfInspectionPut = (payload) => {
  return axios.post(
    `${common_api}/insurance/policy`, payload
  );
};

export const getCustomer = (payload) => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/customerprofile/${payload}`
  );
};

export const getAgent = (payload) => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/agentprofile/${payload}`
  );
};

export const agentActivepanel = (payload) => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/agentinspection/${payload}`
  );
};

export const getProductStats = () => {
  return axios.get(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/business/sales"
  );
};

export const generateOtp = (payload) => {
  return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/messages/v1/sms', payload);
};

export const otpValidation = (payload) => {
  return axios.post("https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/messages/v1/sms", payload);
};

export const validateUserByPhone = (payload) => {
  return axios.post(crm_api + "/admin/validateuser", payload);
};

export const getUsers = (payload) => {
  console.log(payload, "jhdsdfcjdsgakjdfgsdkjhfgdj");
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/usermanagement/${payload}`
  );
};

export const getEmployeeProfile = (phno) => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/user-role/${phno}`
  );
};

export const getPermissions = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/CRM/role.json"
  );
};

export const existUserUpdatePermissions = (empid, payload) => {
  return axios.put(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/userrolepermissions/${empid}`,
    payload
  );
};

export const fetchNewUser = (payload) => {
  console.log("newemployee payload", payload);
  return axios.post(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/userrolepermissions`,
    payload
  );
};

export const getBranchDetails = () => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/branchaddition`
  );
};

export const postBranches = (payload) => {
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/branchaddition",
    payload
  );
};

export const deleteBranch = (payload) => {
  return axios.delete(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/branchaddition/${payload}`
  );
};

export const editBranch = (payload, body) => {
  return axios.put(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/branchaddition/${payload}`,
    body
  );
};

export const postStatusBranches = (payload) => {
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/branchaddition/status",
    payload
  );
};

export const getZones = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/CRM/zone_wise_data.json"
  );
};

export const getReportingManager = () => {
  return axios.get(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/reportingmanagerdata"
  );
};

export const getPagesPermissions = () => {
  return axios.get(
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/CRM/pagesdata.json"
  );
};

export const fetchRenewalData = () => {
  return axios.get(
    "https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/admin/renewalnotification"
  );
};

export const updatePolicyStatus = (customer_id, payload) => {
  return axios.put(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/renewalnotification/${customer_id}`,
    payload
  );
};

export const fetchRenewals = () => {
  return axios.get(
    "https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/admin/renewal"
  );
};

export const getMotorRevenueData = () => {
  return axios.get(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/revenue"
  );
};

export const PostHealthRevenueData = (payload) => {
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/revenue",
    payload
  );
};

export const getOtherPayouts = () => {
  return axios.get(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/revenue"
  );
};

export const payoutsUpload = (payload) => {
  for (let [key, value] of payload.entries()) {
    console.log(`${key}: ${value}`);
  }
  return axios.post(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/financemanagement/others-upload",
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const getPolicyReports = () => {
  return axios.get(
    `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/policy/revenue`
  );
};

export const getLifePlains = () => {
  return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/admin/lifeplans.json')
}
