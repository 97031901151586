import * as React from "react";
import Box from "@mui/material/Box";

import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getSalesData, getSalesDataPost } from "../api/adminService";
// import { fetchSalesData } from "../../../Redux/admin/action";
import { Grid, Tab, Tabs } from "@mui/material";
import { Route, Routes } from "react-router";
import Profile from "./Profile/profile";
import PendingCases from "./PendingCases";
import SelfInspectionPanel from "./SelfInspectionPanel";
import OfflinePolicy from "./OfflineForms/OfflinePolicy";
import SalesInfo from "./Sales/SalesInfo";
// import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction"

function Home() {
  // const employeeId = useSelector((state) => state.adminReducer.loginData.empid);
  // const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(2);
  // const initialRows = useSelector((state) => state.adminReducer.salesData);

  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);

  // useEffect(() => {
  //   const fetchDataAndProcess = async () => {
  //     setLoading(true);
  //     try {
  //       let res;
  //       if (toDate && fromDate) {
  //         const payload = { todate: toDate, fromdate: fromDate };
  //         res = await getSalesDataPost(payload);
  //       } else {
  //         res = await getSalesData(employeeId);
  //       }
  //       const rowsWithId = AddId(res.data);
  //       dispatch(fetchSalesData(rowsWithId));
  //     } catch (error) {
  //        dispatch(setErrorMessage(error.message || error.toString()));
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchDataAndProcess();
  // }, [value]);

  // const AddId = (data) => {
  //   return data.map((row, id) => ({
  //     ...row,
  //     id,
  //   }));
  // };

  // Handle tab change
  const handleChange = (event, newValue) => setValue(newValue);

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return <SalesInfo />;

      case 1:
        return <PendingCases />;

      case 2:
        return <SelfInspectionPanel />;

      case 3:
        return <OfflinePolicy />;

      default:
        return <SalesInfo />;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Box>
            <Tabs
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="tabs example"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab label="Issuance Report" />
              <Tab label="Pending Report" />
              <Tab label="Login Reports" />
              <Tab label="Add Policy" />
            </Tabs>
            {renderTabContent()}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function SalesManagement() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile/customer/:id" element={<Profile />} />
      <Route path="/profile/agent/:id" element={<Profile />} />
    </Routes>
  );
}
