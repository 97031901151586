import axios from "axios";
import { common_api } from "../Config";

export const AddCustomerData = async (customerData) => {
  try {
    let addCustomerResponse = await axios.post(
      `${common_api}/insurance/crm/v1/org/agent/customer`,
      customerData
    );
    return addCustomerResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOtpToNumber = async (req) => {
  try {
    let sendotpresponse = await axios.post(`${common_api}/messages/v1/sms`, req );
    return sendotpresponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOtp = async ( otpData ) => {
  try {
    let res;
    const otpResponse = await sendOtpToNumber(otpData);
    res = otpResponse;
    console.log(res, "response in ADD&sendotp");
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const verifyOtp = async ( otpData ) => {
  try {
    let res;
    const otpResponse = await sendOtpToNumber(otpData);
    res = otpResponse;
    console.log(res, "response in ADD&sendotp");
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
}