import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { Controller } from "react-hook-form";
// import CustomTextField from "../../../../../Loan/components/CustomTextField";

const CustomerOfflineForm = ({
  register,
  errors,
  control,
  setValue,
  setError,
  clearErrors,
  watch,
  getValues,
  product,
  // trigger,
  // isSubmitted,
}) => {


  // const empId = useSelector((state) => state?.adminReducer?.loginData?.empid);
  const title = watch("title", "");


  const [pincodeLoading, setPincodeLoading] = useState(false);
  // const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);

  const [pincodeData, setPincodeData] = useState({});

  const mapingPincode = (data) => {
    const city = [];
    data?.forEach((eachValue) => city.push(eachValue?.Name));
    return { state: data[0]?.State, city };
  };

  const searchPincode = async (e) => {
    setValue("pincode", e);
    if (e?.toString()?.length === 6) {
      setPincodeLoading(true);

      try {
        let api = `https://api.postalpincode.in/pincode/${e}`;
        let response = await axios.get(api);

        if (response?.status === 200) {
          const updatedData = mapingPincode(response?.data[0]?.PostOffice);
          setPincodeData(updatedData);
          clearErrors("pincode");
          setValue("state", response.data[0].PostOffice[0].State);
        } else {
          setValue("state", "");
          setValue("city", "");
          setError("pincode", {
            type: "manual",
            message: "pincode doesn't exist",
          });
        }
      } catch (error) {
        console.log({ error: "internal error" });
      } finally {
        setPincodeLoading(false);
      }
    } else {
      setValue("state", "");
      setValue("city", "");
      setPincodeLoading(false);
    }
  };

  // const searchCustomer = async(value) =>{
  //   if (value?.length === 10) {
  //     dispatch(clearError())
  //     dispatch(clearSuccessMessage())
  //     setPhoneNumberLoading(true);
  //     try {
  //       const response = await fetchCustomerData(value);
  //       if(response?.data?.status === 200){
  //         const {data} = response?.data
  //         await searchPincode(data?.pincode);
  //         setValue("customer_name", data?.customer_name);
  //         setValue("dob", data?.dob);
  //         setValue("gender", data?.gender);
  //         setValue("document_type", data?.document_type);
  //         setValue("document_number", data?.document_number);
  //         setValue("marital_status", data?.marital_status);
  //         setValue("email", data?.email);
  //         setValue("address", data?.address);
  //         setValue("pincode", data?.pincode)
  //         setValue("state", data?.state);
  //         setValue("phone", data?.phone);
  //         setValue("city", data?.city);
  //         dispatch(setSuccessMessage('Customer Details Added Successfuly...'))
  //       }else{
  //         dispatch(
  //           setPopupError("Customer not Found. please Enter Details Manually")
  //         );
  //       }
  //     } catch (e) {
  //       dispatch(
  //         setPopupError("Internal Server Error. please Enter Details Manually")
  //       );
  //     }
  //     setPhoneNumberLoading(false);
  //   }

  // }

  return (
    <>
      <Grid container rowSpacing={4} columnSpacing={3} mb={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Customer Details</Typography>
        </Grid>

        {/* title */}
        {product === "MOTOR" && (
          <Grid item xs={12} md={3}>
            <Controller
              name="title"
              control={control}
              defaultValue=""
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={
                    <>
                      Title <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  variant="outlined"
                  fullWidth
                  error={!!errors.title}
                >
                  <MenuItem value="">
                    <em>Select Title</em>
                  </MenuItem>
                  <MenuItem value="MR">Mr</MenuItem>
                  <MenuItem value="MS">Ms</MenuItem>
                  <MenuItem value="MRS">Mrs</MenuItem>
                  <MenuItem value="MIS">M/s</MenuItem>
                </TextField>
              )}
            />
            {errors.title && (
              <FormHelperText error>{errors.title.message}</FormHelperText>
            )}
          </Grid>
        )}
        {/* customer name */}
        <Grid item xs={12} md={3}>
          <TextField
            {...register("customer_name", {
              required: "Customer Name is Required",
              setValueAs: (value) => value?.toUpperCase(),
            })}
            error={!!errors.customer_name}
            helperText={
              errors.customer_name ? errors.customer_name.message : ""
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            label={
              <>
                {title === "MIS" ? "Company Name" : "Customer Name"}{" "}
                <span style={{ color: "red" }}>*</span>
              </>
            }
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>

        {/* represent name */}
        {title === "MIS" && (
          <Grid item xs={12} md={3}>
            <Controller
              name="representName"
              control={control}
              defaultValue="" // Default value is an empty string
              rules={{
                required: "This Field is required",
                minLength: {
                  value: 3,
                  message: "Name must be at least 3 characters long",
                },
              }} // Validation for Name field
              render={({ field }) => (
                <TextField
                  {...field}
                  label={
                    <>
                      Representative Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  variant="outlined"
                  fullWidth
                  error={!!errors.representName}
                />
              )}
            />
            {errors.representName && (
              <FormHelperText error>
                {errors.representName.message}
              </FormHelperText>
            )}
          </Grid>
        )}

        {/* company type */}
        {product === "MOTOR" && (
          <Grid item xs={12} md={3}>
            <Controller
              name="customer_type"
              control={control}
              defaultValue=""
              // rules={{ required: "customer type is required" }}
              // disabled

              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={
                    <>
                      Customer Type
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </>
                  }
                  variant="outlined"
                  aria-readonly
                  fullWidth
                  // error={!!errors.customer_type}
                  value={
                    title === "MIS"
                      ? "COMPANY"
                      : title === ""
                      ? ""
                      : "INDIVIDUAL"
                  }
                >
                  <MenuItem value="">Select Customer Type</MenuItem>
                  <MenuItem value="INDIVIDUAL">INDIVIDUAL</MenuItem>
                  <MenuItem value="COMPANY">COMPANY</MenuItem>
                </TextField>
              )}
            />
            {/* {errors.customer_type && (
              <FormHelperText error>
                {errors.customer_type.message}
              </FormHelperText>
            )} */}
          </Grid>
        )}

        {/* gst */}
        {title === "MIS" && (
          <Grid item xs={12} md={3}>
            <Controller
              name="gst_number"
              control={control}
              rules={{ required: "Gst number is required" }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={
                    <>
                      Company GST Number <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  variant="outlined"
                  error={!!errors.gst_number}
                  fullWidth
                />
              )}
            />
            {errors.gst_number && (
              <FormHelperText error>{errors.gst_number.message}</FormHelperText>
            )}
          </Grid>
        )}

        {/* phone number */}
        <Grid item xs={12} md={3}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "phone number is Required",
              pattern: {
                value: /^[6-9]\d{9}$/,
                message: "Invalid Phone Number",
              },
            }}
            render={({ field: { onChange } }) => (
              <TextField
                // {...field}
                fullWidth
                // type="number"
                label={
                  <>
                    Phone <span style={{ color: "red" }}>*</span>
                  </>
                }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       {phoneNumberLoading && (
                //         <CircularProgress size={20} color="primary" />
                //       )}
                //     </InputAdornment>
                //   ),
                // }}
                onChange={(e) => {
                  // searchCustomer(e?.target?.value);
                  onChange(e?.target?.value);
                }}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
              />
            )}
          />
        </Grid>

        {/* date of birth */}
        <Grid item xs={12} md={3}>
          <TextField
            {...register("dob", { required: "Date of Birth is Required" })}
            error={!!errors.dob}
            helperText={errors.dob ? errors.dob.message : ""}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            label={
              <>
                Date of Birth <span style={{ color: "red" }}>*</span>
              </>
            }
            type="date"
          />
        </Grid>

        {/* gender */}
        {title !== "MIS" && (
          <Grid item xs={12} md={3}>
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              rules={{ required: "Gender is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  // inputRef={ref}
                  // onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("gender");
                  }}
                  error={!!errors.gender}
                  helperText={errors.gender ? errors.gender.message : ""}
                  fullWidth
                  label={
                    <>
                      Gender <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  select
                  value={value || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                </TextField>
              )}
            />
          </Grid>
        )}

        {/* document type */}
        <Grid item xs={12} md={3}>
          <Controller
            name="document_type"
            control={control}
            // defaultValue=""
            rules={{ required: "Document type is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                inputRef={ref}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e.target.value);
                  clearErrors("document_type");
                }}
                error={!!errors.document_type}
                helperText={
                  errors.document_type ? errors.document_type.message : ""
                }
                fullWidth
                label={
                  <>
                    Document Type <span style={{ color: "red" }}>*</span>
                  </>
                }
                select
                value={value || ""}
              >
                <MenuItem value="PAN">Pan Card</MenuItem>
                <MenuItem value="AADHAR">Aadhaar</MenuItem>
                <MenuItem value="LICENSE">Driving License</MenuItem>
              </TextField>
            )}
          />
        </Grid>

        {/* document number */}
        <Grid item xs={12} md={3}>
          <TextField
            {...register("document_number", {
              required: "Document Number is Required",
              pattern: {
                value:
                  getValues()?.document_type === "PAN"
                    ? /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
                    : getValues()?.document_type === "AADHAR"
                    ? /^\d{4}\s?\d{4}\s?\d{4}$/
                    : /^[A-Z]{2}[0-9]{13}$/,
                message: "Invalid Document Number",
              },
              setValueAs: (value) => value?.toUpperCase(),
            })}
            error={!!errors.document_number}
            helperText={
              errors.document_number ? errors.document_number.message : ""
            }
            fullWidth
            label={
              <>
                Document Number <span style={{ color: "red" }}>*</span>
              </>
            }
            inputProps={{ style: { textTransform: "uppercase" } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* marital status */}
        {title !== "MIS" && (
          <Grid item xs={12} md={3}>
            <Controller
              name="marital_status"
              control={control}
              defaultValue=""
              rules={{ required: "Marital status is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  inputRef={ref}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors("marital_status");
                  }}
                  error={!!errors.marital_status}
                  helperText={
                    errors.marital_status ? errors.marital_status.message : ""
                  }
                  fullWidth
                  label={
                    <>
                      Marital Status <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  select
                  value={value || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="Y">Married</MenuItem>
                  <MenuItem value="N">Unmarried</MenuItem>
                </TextField>
              )}
            />
          </Grid>
        )}

        {/* email */}
        <Grid item xs={12} md={3}>
          <TextField
            {...register("email", {
              required: "Email is Required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email",
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
            fullWidth
            label={
              <>
                Email <span style={{ color: "red" }}>*</span>
              </>
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* address */}
        <Grid item xs={12} md={3}>
          <TextField
            {...register("address", {
              required: "Address is Required",
              pattern: {
                value: /^([a-zA-z0-9/\\''(),-\s]{2,255})$/,
                message: "Invalid Address",
              },
            })}
            error={!!errors.address}
            helperText={errors.address ? errors.address.message : ""}
            fullWidth
            label={
              <>
                Address <span style={{ color: "red" }}>*</span>
              </>
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* pincode */}
        <Grid item xs={12} md={3}>
          <Controller
            name="pincode"
            control={control}
            rules={{
              required: "Pincode is Required",
              pattern: {
                value: /^\d{6}$/,
                message: "Invalid Pincode",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="number"
                label={
                  <>
                    Pincode <span style={{ color: "red" }}>*</span>
                  </>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {pincodeLoading && (
                        <CircularProgress size={20} color="primary" />
                      )}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => searchPincode(e?.target?.value)}
                error={!!errors.pincode}
                helperText={errors.pincode ? errors.pincode.message : ""}
              />
            )}
          />
        </Grid>

        {/* city */}
        <Grid item xs={12} md={3}>
          <Controller
            name="city"
            control={control}
            rules={{
              required: "City is Required",
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={pincodeData?.city?.map((value) => value) || []}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => field.onChange(newValue)}
                defaultValue={null}
                // value={pincodeData?.city?.find((eachValue) => eachValue === field?.value ) || null}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <>
                        City <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    error={!!errors?.city}
                    helperText={errors?.city?.message}
                  />
                )}
              />
            )}
          />
        </Grid>

        {/* state */}
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            {...register("state")}
            name="state"
            value={pincodeData?.state}
            disabled
            label="State"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerOfflineForm;