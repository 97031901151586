import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSelfInspecData, selfInspecDataByFilter, selfInspectionPut } from "../api/adminService";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import TransactionEdit from "./Sales/TransactionEdit";
import { IdBasedPermissions } from "./EmployeePagePermissions/IdBasedPermissions";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction";
import { Link } from "react-router-dom";

const SelfInspectionPanel = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const empId = useSelector((state) => state?.adminReducer?.loginData?.empid);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const empidLogin = useSelector((state) => state.adminReducer.loginData.empid);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "order_id",
      headerName: "Order ID",
      width: 100,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 120,
      renderCell: (params) => {
        return (
          <Link to={`profile/customer/${params.row.customer_id}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 220,
    },
    {
      field: "channels",
      headerName: "Channel Type",
      width: 220,
      renderCell: (params) => params.row.channels?.toUpperCase(),
    },
    ...(IdBasedPermissions.includes(empidLogin)
      ? [
        {
          field: "edit",
          headerName: "Edit",
          width: 80,
          renderCell: (params) => (
            <IconButton
              sx={{ color: "#23a8fa" }}
              onClick={() => handleEditClick(params.row)}
            >
              <EditIcon />
            </IconButton>
          ),
        },
      ]
      : []),

    {
      field: "rc_number",
      headerName: "Vehicle Number",
      width: 100,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_make",
      headerName: "Vehicle Make",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_fuel_type",
      headerName: "Vehicle Fuel Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "commercial_vehicle_type",
      headerName: "Commercial Vehicle Type",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_chassis_number",
      headerName: "Vehicle Chassis Number",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_engine_number",
      headerName: "Vehicle Engine Number",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "product_type",
      headerName: "Product Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "product_subtype",
      headerName: "Product Sub Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_type",
      headerName: "Policy Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_sub_type",
      headerName: "Policy Sub Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "provider",
      headerName: "Provider",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_number",
      headerName: "Policy Number",
      width: 320,
    },
    {
      field: "policy_startdate",
      headerName: "Start Date",
      width: 120,
      renderCell: (params) => params.value.split('T')[0],
    },
    {
      field: "policy_enddate",
      headerName: "End Date",
      width: 120,
      renderCell: (params) => params.value.split('T')[0],
    },
    {
      field: "premium_with_gst",
      headerName: "Gross Premium",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "premium_without_gst",
      headerName: "Net Premium",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "sum_insured",
      headerName: "Sum Insured",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "created_date",
      headerName: "Policy Created Date",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_issue_date",
      headerName: "Policy Issue Date",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "employee_id",
      headerName: "Employee ID",
      width: 120,
    },
    {
      field: "policy_emp_name",
      headerName: "Employee Name",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "agent_id",
      headerName: "Agent ID",
      width: 120,
      renderCell: (params) => {
        return (
          <Link to={`profile/agent/${params.value}`}>
            {params.value !== '1000' ? params.value : null}
          </Link>
        );
      },
    },
    {
      field: "agent_name",
      headerName: "Agent Name",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase()
    },
    {
      field: "telecaller_id",
      headerName: "Telecaller ID",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase()
    },
    {
      field: "telecaller_name",
      headerName: "Telecaller Name",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    { field: "reporting_manager_name", headerName: "Reporting Employee Name", width: 180 },
    { field: "reporting_manager_id", headerName: "Reporting Employee ID", width: 180 },
    { field: "agent_emp_name", headerName: "Agent Reporting Employee ID", width: 180 },
    { field: "agent_employee_id", headerName: "Agent Reporting Employee Name", width: 180 },
    {
      field: "state",
      headerName: "Employee State",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "district",
      headerName: "Employee Location",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "reason",
      headerName: "Reason",
      with: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "pdf_link",
      headerName: "PDF Download",
      width: 180,
      renderCell: (params) => (
        console.log(params.row.pdf_link, 'params.row.pdf_link'),
        params.row.pdf_link ? (
          <a href={params.row.pdf_link} target="_blank" rel="noopener noreferrer">
            Download PDF
          </a>
        ) : (
          // Optionally show "No PDF" or any other fallback content
          <span>No PDF available</span>
        )
      ),
    }

  ];

  const AddId = (data) => {
    return data.map((row, id) => ({
      ...row,
      id,
      customer_name: row.customer_name ? row.customer_name : row.insurer_name
    }));
  };

  const getAllData = async () => {
    setLoading(true);
    try {
      const response = await getSelfInspecData(empId);
      setRows(AddId(response.data));
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, [empId]);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
    Object.keys(row).forEach((key) => {
      setValue(key, row[key]);
    });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await selfInspecDataByFilter({ ...data, empid: empId });
      setRows(AddId(response.data));
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
      handleDialogClose();
    }
  };

  const onReset = () => {
    reset();
    getAllData();
  };

  const CustomGridToolbar = () => {
    return <Toolbar>
      <GridToolbarQuickFilter />
      <Box flexGrow={1} />
      <GridToolbarExport />
    </Toolbar>
  }

  return (
    <>
      {loading ? (
        <CircularProgress
          size={50}
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <Grid
          container
          rowSpacing={2}
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* Main Form with Fromdate and Todate */}
          <Grid item xs={7}>
            <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
              <Grid
                container
                display="flex"
                alignItems="center"
                columnSpacing={2}
              >
                <Grid item xs={4}>
                  <TextField
                    {...register("fromdate", {
                      required: "From Date is required",
                    })}
                    type="date"
                    label="From Date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    error={!!errors.fromdate}
                    helperText={errors.fromdate ? errors.fromdate.message : ""}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    {...register("todate", { required: "To Date is required" })}
                    type="date"
                    label="To Date"
                    error={!!errors.todate}
                    helperText={errors.todate ? errors.todate.message : ""}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      min: watch("fromdate"),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="reset"
                    variant="contained"
                    endIcon={<RestartAltIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          {/* Data Table */}
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              slots={{
                toolbar: () => {
                  return (
                    <CustomGridToolbar />
                  );
                },
              }}
              sortModel={[{ field: 'order_id', sort: 'desc' }]}
            />
          </Grid>

          {/* Edit Dialog */}
          <TransactionEdit
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            loading={loading}
            setLoading={setLoading}
            setRows={setRows}
            columns={columns}
          />
        </Grid>
      )}
    </>
  );
};

export default SelfInspectionPanel;
