import React, {  useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { fetchRenewalData, updatePolicyStatus } from "../api/adminService";
import {
  Divider,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { handleRenewalNotification } from "../../../Redux/admin/action";
import { useDispatch, useSelector } from "react-redux";

const dateFormat = (date) => {
  const dateObj = new Date(date);
  const [y, m, d] = dateObj.toISOString().split("T")[0].split("-");
  return `${d}-${m}-${y}`;
};

export default function DataGridDemo() {
  const renewalData = useSelector(
    (state) => state.adminReducer.RenewalNotificationData
  );
  const dispatch = useDispatch();
  const rows = renewalData;

  const getRenewalData = async () => {
    try {
      let response = await fetchRenewalData();
      const addID = (data) => data.map((row, id) => ({ ...row, id }));
      dispatch(handleRenewalNotification(addID(response.data)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRenewalData();
  }, [dispatch]);

  const columns = [
    { field: 'agent_id', headerName: 'Agent ID', width: 150 },
    { field: 'customer_id', headerName: 'Customer ID', width: 150 },
    { field: 'product_type', headerName: 'Product Type', width: 180 },
    { field: 'rc_number', headerName: 'RC Number', width: 150 },
    { field: 'policy_type', headerName: 'Policy Type', width: 180 },
    { field: 'policy_sub_type', headerName: 'Policy Sub Type', width: 180 },
    { field: 'provider', headerName: 'Provider', width: 250 },
    { field: 'policy_number', headerName: 'Policy Number', width: 200 },
    { field: 'application_id', headerName: 'Application ID', width: 250 },
    { field: 'policy_status', headerName: 'Policy Status', width: 180 },
    { field: 'policy_startdate', headerName: 'Policy Start Date', width: 200},
    { field: 'policy_enddate', headerName: 'Policy End Date', width: 200},
    { field: 'created_date', headerName: 'Created Date', width: 200 },
    { field: 'sum_insured', headerName: 'Sum Insured', width: 180 },
    { field: 'premium_with_gst', headerName: 'Premium With GST', width: 200 },
    { field: 'premium_without_gst', headerName: 'Premium Without GST', width: 200 },
    { field: 'tenure', headerName: 'Tenure', width: 150 },
  ];

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }} p={2}>
          Renewal Notification Panel
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12}>
        <Box
          sx={{
            height: "calc(100vh - 180px)",
            width: "100%",
            "& .actions": { color: "text.secondary" },
            "& .textPrimary": { color: "text.primary" },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            pageSizeOptions={[5, 10, 12]}
            disableRowSelectionOnClick
          />
        </Box>
      </Grid>
    </Grid>
  );
}
