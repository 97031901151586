import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getPolicyReports } from "../../api/adminService";
import { CircularProgress, Typography } from "@mui/material";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { useDispatch } from "react-redux";

export default function PolicyReports() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const columns = [
    { field: "agent_id", headerName: "Agent ID", width: 150 },
    { field: "employee_id", headerName: "Employee ID", width: 150 },
    { field: "policy_id", headerName: "Policy ID", width: 150 },
    { field: "policy_num", headerName: "Policy Number", width: 200 },
    { field: "product_type", headerName: "Product Type", width: 150 },
    { field: "product_subtype", headerName: "Product Subtype", width: 150 },
    { field: "motor_type", headerName: "Motor Type", width: 150 },
    { field: "revenue", headerName: "Revenue", type: "number", width: 200 },
    { field: "created_date", headerName: "Created Date", width: 200 },
    {
      field: "sum_insured",
      headerName: "Sum Insured",
      type: "number",
      width: 150,
    },
    {
      field: "od_premium",
      headerName: "OD Premium",
      type: "number",
      width: 150,
    },
    {
      field: "tp_premium",
      headerName: "TP Premium",
      type: "number",
      width: 150,
    },
    {
      field: "premium_without_gst",
      headerName: "Premium (Without GST)",
      type: "number",
      width: 200,
    },
    {
      field: "premium_with_gst",
      headerName: "Premium (With GST)",
      type: "number",
      width: 200,
    },
  ];

  const getAllPolicyReports = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await getPolicyReports();
      if (res.status === 200) {
        const flattenedData = res.data.flat();
        setRows(mapDataWithId(flattenedData));
      } else {
        dispatch(setErrorMessage("Failed to Fetch Data"));
      }
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const mapDataWithId = (data) => data.map((row, id) => ({ ...row, id }));

  React.useEffect(() => {
    getAllPolicyReports();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{mt:3, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
      />
    </Box>
  );
}
