
import React, { useState } from 'react';
import { TextField, Button, Card, Typography, Box, Stack, CardContent, Alert, Slide } from '@mui/material';
import { Link } from 'react-router-dom';



function CompanySignin() {
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        // Simulate a network request
        setTimeout(() => {
            alert('Phone: ' + phone + ' OTP: ' + otp);
            setLoading(false);
        }, 2000);
    };

    return (

        <Box minHeight={'100dvh'} component={Stack} sx={{ background: 'url(https://img.freepik.com/premium-vector/social-network-connection-people-blue-banner-background_998164-512.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: "cover" }}>
            <Box flexGrow={1} component={Stack} alignItems={'center'} justifyContent={'center'}>
                <Slide timeout={600} direction="up" in={true} mountOnEnter unmountOnExit>
                    <Card elevation={6}>
                        <CardContent>
                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: 400,
                                    margin: 'auto'
                                }}
                            >
                                <Typography gutterBottom variant='h3'>
                                    Company Login
                                </Typography>
                                <Box mb={2} />
                                <TextField
                                    label="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    type="tel"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    sx={{ marginBottom: 2 }}
                                    required
                                />

                                <TextField
                                    label="OTP"
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    sx={{ marginBottom: 2 }}
                                    required
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={loading || !phone || !otp}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </Button>
                            </Box>

                        </CardContent>
                    </Card>
                </Slide>
                <Box mt={3} />
                <Typography variant="h5">New Company ? Register <Link to="/company/company-signup">here </Link></Typography>
            </Box>
        </Box>
    );
}

export default CompanySignin;