import React, { useState, lazy, Suspense, useEffect, useRef } from "react";
import {
  Tab,
  Tabs,
  Container,
  Paper,
  Typography,
  Grid,
  Modal,
  Box,
  Button,
  Alert,
} from "@mui/material";
import TabPanel from "../../Pages/TabPanel";
import PolicyDetails from "../../Pages/Health info/PolicyDetails";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "../../Pages/ProposalForm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../Components/Forms/ProposerDetailsForm.css";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import LoaderComponent from "../../../Framework/Components/Loader";
const ProposerDetailsForm = lazy(() => import("../Forms/ProposerDetailsForm"));
const NomineeDetailsForm = lazy(() => import("../Forms/NomineeDetails"));
const MedicalDetails = lazy(() => import("../Forms/MedicalDetails"));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProposalForm = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabDisabled, setTabDisabled] = useState([false, true, true, true]);
  const [proposerData, setProposerData] = useState(null);
  const [nomineeData, setNomineeData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [responseText, setResponseText] = useState("");

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
  } = useForm();
  const [modalproposerData, setmodalproposerData] = useState(null);
  const [model, setIsModelTrue] = useState(true);
  const [open, setOpen] = useState(false);
  const FirstNameRef = useRef(null);
  const LastNameRef = useRef(null);
  useEffect(() => {
    setOpen(true);
  }, []);

  const insurerData = useSelector(
    (state) => state?.healthReducer?.proposerData
  );
  console.log(insurerData, "hasjhas");

  const proposerGender = useSelector(
    (state) => state?.healthReducer?.proposerGender
  );

  const proposalBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );

  const familyData = useSelector((state) => state?.healthReducer?.familyData);
  const sonCount = useSelector(
    (state) => state?.healthReducer?.familyData?.sonCount
  );

  const daughterCount = useSelector(
    (state) => state?.healthReducer?.familyData?.daughterCount
  );
  const kycResponse = useSelector((state) => state?.healthReducer?.kycResponse);

  // console.log(kycResponse);
  // console.log("member details to send into api", formData);
  // console.log(familyData, sonCount, daughterCount, "proposal form");
  // console.log("proposerDate", proposerData);
  // console.log("nomineeData", nomineeData);
  // console.log("proposalBody", proposalBody);
  // console.log("proposergender", proposerGender);

  // const handleClose = () => setOpen(false);
  const handleClose = (value) => {
    if (value === "Yes") {
      setmodalproposerData(insurerData);

      setValue(`selfFirstName0`, insurerData?.firstname);
      setValue(`selfLastName0`, insurerData?.lastname);
      setValue("selfDOB0", insurerData?.birthDate);
      setValue("maritalStatusself0", insurerData?.marital_status);
      setOpen(false);
    } else {
      setOpen(false);
    }
  };
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day); // Note: Month is 0-indexed
  };

  const handleYes = () => {
    setmodalproposerData(insurerData);
    setOpen(false);
    setIsModelTrue(false);
  };

  const handleNo = () => {
    setmodalproposerData(null);
    setOpen(false);
    setIsModelTrue(false);
  };

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const handleNextTab = (tabIndex) => {
    setTabDisabled((prevTabDisabled) =>
      prevTabDisabled.map((_, index) => (index > tabIndex ? true : false))
    );
    setCurrentTab(tabIndex);
  };

  const relationOptions = [
    "self",
    "spouse",
    "son",
    "daughter",
    "father",
    "mother",
    "father in law",
    "mother in law",
  ];

  const renderMemberForms = (memberType, memberCount) => {
    if (!familyData[memberType]) {
      return null;
    }
    const memberForms = [];
    for (let i = 0; i < memberCount; i++) {
      let title;
      let gender;
      let relationLabel;
      if (memberType === "self" && proposerGender === "male") {
        title = "MR";
        gender = "MALE";
        relationLabel = "self";
      } else if (memberType === "self" && proposerGender === "female") {
        title = "MS";
        gender = "FEMALE";
        relationLabel = "self";
      } else if (memberType === "spouse" && proposerGender === "male") {
        title = "MS";
        gender = "FEMALE";
        relationLabel = "spouse";
      } else if (memberType === "spouse" && proposerGender === "female") {
        title = "MR";
        gender = "MALE";
        relationLabel = "spouse";
      } else if (memberType === "son" || memberType === "father") {
        title = "MR";
        gender = "MALE";
        relationLabel = memberType;
      } else if (memberType === "daughter") {
        title = "MS";
        gender = "FEMALE";
        relationLabel = memberType;
      } else if (memberType === "mother") {
        title = "MS";
        gender = "FEMALE";
        relationLabel = memberType;
      }
      // const parseDate = (dateString) => {
      //   const parts = dateString.split("/");
      //   return new Date(parts[2], parts[1] - 1, parts[0]);
      // };

      memberForms.push(
        <div key={i}>
          <h6 className="member_details_heading ms-2">
            {`${
              memberType.toUpperCase() !== "SELF" &&
              memberType.toUpperCase() !== "SPOUSE" &&
              memberType.toUpperCase() !== "MOTHER" &&
              memberType.toUpperCase() !== "FATHER" &&
              memberType.toUpperCase() !== "FATHERINLAW" &&
              memberType.toUpperCase() !== "MOTHERINLAW"
                ? `${memberType.toUpperCase()} ${i + 1}`
                : memberType.toUpperCase()
            }`}
          </h6>
          {model ? (
            <div>
              <Modal
                open={open}
                onClose={() => handleClose("No")}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={style}>
                  <p id="modal-description">is Proposer also Insurer ? </p>
                  <Button
                    onClick={() => handleClose("Yes")}
                    variant="contained"
                    color="primary"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => handleClose("No")}
                    variant="contained"
                    color="secondary"
                    sx={{ ml: 2 }}
                  >
                    No
                  </Button>
                </Box>
              </Modal>
            </div>
          ) : null}
          <div key={i} className="proposer_details_form_1">
            <div className="row">
              {/* title */}
              <div className="col-sm-4 justify-content-center">
                <div className="inputGroup">
                  <label htmlFor={`${memberType}Title${i}`}>
                    {errors?.[`${memberType}Title${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}Title${i}`]?.message}
                      </p>
                    ) : (
                      "Title"
                    )}
                  </label>
                  <select
                    className="form_control"
                    {...register(`${memberType}Title${i}`, {
                      required: "Title is required",
                    })}
                    value={title}
                  >
                    <option value="MR">MR</option>
                    <option value="MS">MRS</option>
                    <option value="MS">MS</option>
                  </select>
                </div>
              </div>

              {/* first name */}
              <div className="col-sm-4 justify-content-center">
                <div className="inputGroup">
                  <label htmlFor={`${memberType}FirstName${i}`}>
                    {errors?.[`${memberType}FirstName${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}FirstName${i}`]?.message}
                      </p>
                    ) : (
                      "First Name *"
                    )}
                    {/* <span className="star"> *</span> */}
                  </label>
                  <input
                    {...register(`${memberType}FirstName${i}`, {
                      required: "First Name is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/, // Allows letters and spaces
                        message:
                          "First Name should only contain letters and spaces",
                      },
                    })}
                    placeholder="Enter Firstname"
                    // defaultValue={
                    //   memberType === "self" && modalproposerData?.firstname
                    //     ? modalproposerData.firstname
                    //     : ""
                    // }
                  />
                </div>
              </div>

              {/* last name */}
              <div className="col-sm-4">
                <div className="inputGroup">
                  <label htmlFor={`${memberType}LastName${i}`}>
                    {errors?.[`${memberType}LastName${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}LastName${i}`]?.message}
                      </p>
                    ) : (
                      "Last Name *"
                    )}
                    {/* <span className="star"> *</span> */}
                  </label>
                  <input
                    // id="lastNameField"
                    // ref={LastNameRef}
                    {...register(`${memberType}LastName${i}`, {
                      required: "Last Name is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/, // Allows letters and spaces
                        message:
                          "Last Name should only contain letters and spaces",
                      },
                    })}
                    placeholder="Enter Lastname"
                    // tabIndex="-1"
                    // defaultValue={
                    //   modalproposerData && memberType === "self"
                    //     ? modalproposerData.lastname
                    //     : ""
                    // }
                  />
                </div>
              </div>

              {/* gender */}
              <div className="col-sm-4 justify-content-center">
                <div className="inputGroup">
                  <label htmlFor={`${memberType}Gender${i}`}>
                    {errors?.[`${memberType}Gender${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}Gender${i}`]?.message}
                      </p>
                    ) : (
                      "Gender *"
                    )}
                  </label>
                  <select
                    className="form_control"
                    {...register(`${memberType}Gender${i}`, {
                      required: "Gender is required",
                    })}
                    value={gender}
                  >
                    <option value="" disabled selected>
                      Select Gender
                    </option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                </div>
              </div>

              {/* date of birth */}
              <div className="col-sm-4">
                <div className="date_picker_div">
                  <label htmlFor="dob">
                    {errors?.[`${memberType}DOB${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}DOB${i}`]?.message}
                      </p>
                    ) : (
                      "Date Of Birth *"
                    )}
                  </label>
                  <Controller
                    control={control}
                    required
                    name={`${memberType}DOB${i}`}
                    rules={{ required: "DOB is required" }}
                    render={({ field }) => (
                      <DatePicker
                        selected={
                          field.value
                            ? typeof field.value === "string"
                              ? parseDate(field.value) // Convert if it's a string
                              : field.value // Use directly if already a Date object
                            : null
                        }
                        // {...register(`${memberType}DOB${i}`, {
                        //   required: "DOB is required",
                        // })}
                        onChange={(date) => field.onChange(date)}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Select Date of birth"
                      />
                    )}
                  />
                </div>
              </div>

              {/* marital status */}
              <div className="col-sm-4 justify-content-center">
                <div className="inputGroup">
                  <label htmlFor="martialStatus">
                    {errors?.[`maritalStatus${memberType}${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`maritalStatus${memberType}${i}`]?.message}
                      </p>
                    ) : (
                      "Marital Status *"
                    )}
                  </label>
                  <select
                    className="form_control"
                    name="martialStatus"
                    // required={true}
                    {...register(`maritalStatus${memberType}${i}`, {
                      required: "Marital Status is required",
                    })}
                    defaultValue={
                      memberType === "spouse" ||
                      memberType === "father" ||
                      memberType === "mother" ||
                      memberType === "fatherInLaw" ||
                      memberType === "motherInLaw"
                        ? "married"
                        : ""
                    }
                  >
                    <option value="">Select Marital Status</option>
                    <option value="married">Married</option>
                    <option value="unmarried">Unmarried</option>
                  </select>
                </div>
              </div>

              {/* relationship */}
              <div className="col-sm-4 justify-content-center">
                <div className="inputGroup">
                  <label htmlFor="relationship">
                    {errors?.[`relationshipStatus${memberType}${i}`] ? (
                      <p className="text-danger">
                        {
                          errors?.[`relationshipStatus${memberType}${i}`]
                            ?.message
                        }
                      </p>
                    ) : (
                      "RelationShip *"
                    )}
                  </label>
                  <select
                    className="form_control"
                    name="relationshipStatus"
                    {...register(`relationshipStatus${memberType}${i}`, {
                      required: "RelationShip is required",
                    })}
                    // required={true}
                    defaultValue={relationLabel}
                  >
                    {" "}
                    <option value="" selected disabled>
                      Select Relation
                    </option>
                    {relationOptions.map((relation, index) => (
                      <option key={index} value={relation}>
                        {relation}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* height */}
              <div className="col-sm-4">
                <div className="inputGroup">
                  <label htmlFor="height">
                    {errors?.[`${memberType}Height${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}Height${i}`]?.message}
                      </p>
                    ) : (
                      "Height in cms *"
                    )}
                  </label>
                  <input
                    type="number"
                    name="height"
                    placeholder="Enter Height "
                    // required={true}
                    {...register(`${memberType}Height${i}`, {
                      required: "Height is required",
                    })}
                  />
                </div>
              </div>

              {/* weight */}
              <div className="col-sm-4">
                <div className="inputGroup">
                  <label htmlFor="weight">
                    {errors?.[`${memberType}Weight${i}`] ? (
                      <p className="text-danger">
                        {errors?.[`${memberType}Weight${i}`]?.message}
                      </p>
                    ) : (
                      "Weight in kgs  *"
                    )}
                  </label>
                  <input
                    type="number"
                    name="weight"
                    placeholder="Enter Weight"
                    // required={true}
                    {...register(`${memberType}Weight${i}`, {
                      required: "Weight is Required",
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return memberForms;
  };

  // tab one submit
  const handleProposalSubmit = async (data) => {
    setProposerData(data);
    handleNextTab(1);
  };
  console.log(modalproposerData, "modalproposerDatamodalproposerData");

  // tab two submit
  const onSubmit = (data) => {
    if (FirstNameRef.current) {
      FirstNameRef.current.blur();
      alert("First Name blurred");
    }

    if (LastNameRef.current) {
      LastNameRef.current.blur();
      alert("Last Name blurred");
    }
    const membersData = {};

    Object.keys(familyData).forEach((memberType) => {
      if (familyData[memberType]) {
        if (memberType === "son" || memberType === "daughter") {
          for (let i = 0; i < familyData[`${memberType}Count`]; i++) {
            const memberKey = `${memberType}${i + 1}`;
            membersData[memberKey] = {
              title: memberType === "son" ? "MR" : "MS",
              firstName: data[`${memberType}FirstName${i}`],
              lastName: data[`${memberType}LastName${i}`],
              gender: data[`${memberType}Gender${i}`],
              dob: moment(data[`${memberType}DOB${i}`]).format("DD/MM/YYYY"),
              maritalStatus: data[`maritalStatus${memberType}${i + 1}`],
              relationship: memberType,
              height: parseInt(data[`${memberType}Height${i}`]),
              weight: parseInt(data[`${memberType}Weight${i}`]),
            };
          }
        } else {
          const memberKey = `${memberType}${
            familyData[`${memberType}Count`] > 1
              ? familyData[`${memberType}Count`]
              : ""
          }`;
          membersData[memberKey] = {
            title: data[`${memberType}Title0`],
            firstName: data[`${memberType}FirstName0`],
            lastName: data[`${memberType}LastName0`],
            gender: data[`${memberType}Gender0`],
            dob:
              modalproposerData && memberType === "self"
                ? modalproposerData.birthDate
                : moment(data[`${memberType}DOB0`]).format("DD/MM/YYYY"),
            maritalStatus: data[`maritalStatus${memberKey}`],
            relationship: memberType,
            height: parseInt(data[`${memberType}Height0`]),
            weight: parseInt(data[`${memberType}Weight0`]),
          };
        }
      }
    });

    delete membersData.sonCount;
    delete membersData.daughterCount;

    setFormData(membersData);
    handleNextTab(2);
  };
 
  const customHandleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Blur the inputs to avoid focus
    if (FirstNameRef.current) {
      FirstNameRef.current.blur();
    }

    if (LastNameRef.current) {
      LastNameRef.current.blur();
    }

    // Now trigger the normal submit behavior
    handleSubmit(onSubmit)();
  };

  // tab three submit
  const handleNomineeSubmit = async (data) => {
    setNomineeData(data);
    handleNextTab(3);
  };

  const submitbtn = {
    display: "inline-block",
    borderRadius: "7px",
    border: "none",
    background: "#23a8fa",
    color: "white",
    fontFamily: "inherit",
    textAlign: "center",
    fontSize: "13px",
    boxShadow: "0px 14px 16px -11px #23a8fa",
    width: "10em",
    padding: "10px 5px",
    transition: "all 0.4s",
    cursor: "pointer",
  };

  return (
    <>
      <Grid container spacing={1}>
        {/* Left Side Panel */}
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} style={{ padding: 10, height: "100%" }}>
            <PolicyDetails />
          </Paper>
        </Grid>

        {/* Right Content */}
        <Grid item xs={12} sm={9}>
          <Paper elevation={3} style={{ padding: 8, height: "100%" }}>
            <Paper
              elevation={3}
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                indicatorColor="primary"
                textColor="primary"
                sx={{ maxWidth: "100%" }}
              >
                <Tab label="Proposer Details" disabled={tabDisabled[0]} />
                <Tab label="Member Details" disabled={tabDisabled[1]} />
                <Tab label="Nominee Details" disabled={tabDisabled[2]} />
                <Tab label="Medical Details" disabled={tabDisabled[3]} />
              </Tabs>
            </Paper>

            <div className="tabs_contant">
              {/* tab 0 */}
              <TabPanel value={currentTab} index={0}>
                <Suspense fallback={<LoaderComponent />}>
                  <ProposerDetailsForm
                    onSubmitProposal={handleProposalSubmit}
                  />
                </Suspense>
              </TabPanel>

              {/* tab 1 */}
              <TabPanel value={currentTab} index={1}>
                <form onSubmit={customHandleSubmit}>
                  {renderMemberForms("self", 1)}
                  {renderMemberForms("spouse", 1)}
                  {renderMemberForms("son", familyData.sonCount)}
                  {renderMemberForms("daughter", familyData.daughterCount)}
                  {renderMemberForms("mother", 1)}
                  {renderMemberForms("father", 1)}
                  {renderMemberForms("motherInLaw", 1)}
                  {renderMemberForms("fatherInLaw", 1)}
                  <div
                    className="submit-button-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative", // Make the container a positioning context
                    }}
                  >
                    <button type="submit" style={submitbtn}>
                      Submit
                    </button>

                    {responseText && (
                      <Alert
                        severity="error"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: "50%",
                          transform: "translateY(-50%)",
                          margin: 0,
                        }}
                      >
                        {responseText}
                      </Alert>
                    )}
                  </div>
                </form>
              </TabPanel>

              {/* tab 2 */}
              <TabPanel value={currentTab} index={2}>
                <Suspense fallback={<LoaderComponent />}>
                  <NomineeDetailsForm onSubmit={handleNomineeSubmit} />
                </Suspense>{" "}
              </TabPanel>

              {/* tab 3 */}
              <TabPanel value={currentTab} index={3}>
                <Suspense fallback={<LoaderComponent />}>
                  <MedicalDetails
                    membersData={formData}
                    nomineeData={nomineeData}
                  />
                </Suspense>
              </TabPanel>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalForm;
