import { Grid, TextField, Typography, Stack, Box, Button, MenuItem, Paper, Icon, IconButton, ListItemIcon, ListItemText, ListItem, ListItemAvatar, Avatar, Divider, LinearProgress } from '@mui/material';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';
import HighchartsReact from 'highcharts-react-official';
import MainCard from "../../../crm/posp/ui-component/cards/MainCard"
import Highcharts from 'highcharts';
import TwoWheelerTwoToneIcon from '@mui/icons-material/TwoWheelerTwoTone';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import AirplanemodeActiveRoundedIcon from '@mui/icons-material/AirplanemodeActiveRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import axios from 'axios';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
const BusinessManagement = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filters, setFilters] = useState({
    frmDate: null,
    toDate: null,
    productType: 'ALL',
    period: 'WEEKLY',
    provider: 'ALL'
  })
  const [resData, setResData] = useState(null);
  const motorInsuranceCompanies = [
    { name: "Acko General Insurance Limited" },
    { name: "Agriculture Insurance Company of India Limited" },
    { name: "Bajaj Allianz General Insurance Company Limited" },
    { name: "Cholamandalam MS General Insurance Company Limited" },
    { name: "Go Digit General Insurance Limited" },
    { name: "Zurich Kotak General Insurance Company (India) Limited" },
    { name: "Kshema General Insurance Limited" },
    { name: "Liberty General Insurance Limited" },
    { name: "Magma HDI General Insurance Company Limited" },
    { name: "National Insurance Company Limited" },
    { name: "Navi General Insurance Limited" },
    { name: "Raheja QBE General Insurance Co. Ltd." },
    { name: "Reliance General Insurance Company Limited" },
    { name: "Royal Sundaram General Insurance Company Limited" },
    { name: "SBI General Insurance Company Limited" },
    { name: "Shriram General Insurance Company Limited" },
    { name: "Tata AIG General Insurance Company Limited" },
    { name: "United India Insurance Company Limited" },
    { name: "Universal Sompo General Insurance Company Limited" },
    { name: "Zuno General Insurance Ltd." },
    { name: "Future Generali India Insurance Company Limited" },
    { name: "The Oriental Insurance company Limited" },
    { name: "The New India Assurance Company Limited" },
    { name: "IFFCO TOKIO General Insurance company Limited" },
    { name: "HDFC ERGO General Insurance Company Limited" },
    { name: "ICICI LOMBARD General Insurance Company Limited" }
  ]

  const iconsMapper = [
    <TrendingUpTwoToneIcon />,
    <TwoWheelerTwoToneIcon />,
    <HealthAndSafetyRoundedIcon />,
    <FavoriteRoundedIcon />,
    <AirplanemodeActiveRoundedIcon />,
    <SavingsRoundedIcon />,

  ]

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: `${filters.period ? filters.period : 'Daily'} Sales`
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Sales Count'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    xAxis: {
      categories: resData && resData.chart.categories,
    },
    series: resData && resData.chart.series
  };


  const getResponse = async (filters) => {
    console.log(filters, 'payload')
    setLoading(true)
    try {
      const res = await axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/business/sales', filters);
      console.log(res.data, 'totalResponse');
      setResData(res.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filters.toDate && filters.frmDate) {
      getResponse({
        ...filters,
        period: null
      })
    } else {
      if (filters.toDate || filters.frmDate) {
        // do nothing any date is selected
      } else {
        getResponse({
          ...filters,
          toDate: null,
          frmDate: null
        })
      }
    }
  }, [filters])

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }))
  }
  const reset = () => setFilters({
    frmDate: null,
    toDate: null,
    productType: 'ALL',
    period: 'WEEKLY',
    provider: 'ALL'
  })


  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      frmDate: newStartDate,
    }));

    // Automatically reset end date if it's less than start date
    if (filters.toDate && newStartDate > filters.toDate) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        toDate: null,
      }));
    }

    setErrorMessage(""); // Clear any previous error message
  };

  // Handle change for end date
  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;

    if (newEndDate && newEndDate < filters.frmDate) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        toDate: filters.frmDate, // Reset to start date if end date is less than start date
      }));
      setErrorMessage("End date cannot be before start date.");
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        toDate: newEndDate,
      }));
      setErrorMessage(""); // Clear error message when valid
    }
  };

  const maxStartDate = dayjs().format("YYYY-MM-DD"); // Max date for start is today
  const maxEndDate = filters.frmDate ? dayjs(filters.frmDate).add(30, "day").format("YYYY-MM-DD") : maxStartDate; // Max end date is 30 days after start date


  return (
    <Box>
      <Grid container p={3} rowGap={2}>
        <Grid item xs={4}>
          <Typography variant="h4">Sales Bussiness</Typography>
        </Grid>
        <Grid item xs={8} component={Stack} justifyContent="flex-end" gap={2}>
          <Stack direction='row' gap={2}>
            <TextField
              size="small"
              label="Start Date"
              type="date"
              value={filters.frmDate || ""}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: maxStartDate, // Max date for start date is today
              }}
              fullWidth
            />
            <br />
            <TextField
              size="small"
              label="End Date"
              type="date"
              value={filters.toDate || ""}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: filters.frmDate || "", // Min end date is the selected start date
                max: maxEndDate, // Max end date is 30 days after the start date
              }}
              fullWidth
            />
            {errorMessage && (
              <p style={{ color: "red", marginTop: "8px" }}>{errorMessage}</p>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4} />
        <Grid
          item
          xs={8}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          outline="none"
          gap={2}
        >
          <Button
            variant="outlined"
            startIcon={<RotateLeftIcon />}
            onClick={reset}
          >
            Reset
          </Button>
          <TextField
            size="small"
            sx={{ minWidth: "160px" }}
            label="Product Type"
            select
            name="productType"
            value={filters['productType']}
            onChange={handleFilter}
          >
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="MOTOR">Motor</MenuItem>
            <MenuItem value="HEALTH">Health</MenuItem>
            <MenuItem value="TRAVEL">Travel</MenuItem>
            <MenuItem value="LOAN">Loan</MenuItem>
            <MenuItem value="LIFE">Life</MenuItem>
          </TextField>
          <TextField
            size="small"
            sx={{ minWidth: "160px" }}
            label="Period"
            select
            disabled={filters.toDate || filters.frmDate}
            name="period"
            value={filters['period']}
            onChange={handleFilter}
          >
            <MenuItem value="WEEKLY">Weekly</MenuItem>
            <MenuItem value="MONTHLY">Monthly</MenuItem>
            <MenuItem value="YEARLY">Yearly</MenuItem>
          </TextField>
          <TextField
            onChange={handleFilter}
            size="small"
            sx={{ minWidth: "160px" }}
            label="Provider"
            select
            value={filters['provider']}
            name="provider"
          >
            <MenuItem value="ALL">ALL</MenuItem>
            {motorInsuranceCompanies.map((cmp) => {
              return <MenuItem value={cmp.name}>{cmp.name}</MenuItem>
            })}
          </TextField>
        </Grid>
      </Grid>
      {(resData && !loading) ? <Box>
        <MainCard>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </MainCard>
        <Box>
          <Stack flexWrap='wrap' direction='row' gap={2} mt={2}>
            {
              resData && resData.salesCount.map((data, _) => {
                return <MainCard sx={{ flexGrow: 1, minWidth: 250 }} key={_}>
                  <ListItem disableGutters disablePadding secondaryAction={<IconButton><Typography variant='h1'>{data.count}</Typography></IconButton>}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "#23a8fa", color: "#fff" }}>
                        {iconsMapper[_]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<>{data.title}</>}></ListItemText>
                  </ListItem>
                </MainCard>
              })
            }
          </Stack>
        </Box>
        <Box mt={2}>
          <Typography variant='h4' gutterBottom>Channel Sales</Typography>
          <Stack direction='row' gap={2} flexWrap='wrap'>
            {resData && resData.salesPremium
              .map((data, index) => {
                return <MainCard sx={{ flexGrow: 1, minWidth: 300 }} key={index}>
                  <ListItem disableGutters secondaryAction={data.total}>
                    <ListItemText><Typography variant='h4' fontWeight={900}>{data.channel}</Typography></ListItemText>
                  </ListItem>
                  <Divider />
                  {data.sales.map((sub) => {
                    return <ListItem divider disableGutters secondaryAction={sub.premium}>
                      <ListItemText>{sub.title}</ListItemText>
                    </ListItem>
                  })}
                </MainCard>
              })}
          </Stack>
        </Box>
      </Box> : <LinearProgress />}

    </Box>
  )
}

export default BusinessManagement;