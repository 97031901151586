import { Box, Button, Card, CardContent, Divider, Grid, ListItem, Stack, Step, StepLabel, Stepper, TextField, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";


const CompanySignup = () => {
    const theme = useTheme();

    return <Box minHeight={'100dvh'} component={Stack}>
        <Box flexGrow={1} component={Stack} alignItems={'center'} justifyContent={'center'}>
            <Stepper activeStep={1} alternativeLabel>
                {["Dealer Details", "Address Details", "Bank Details", "KYC Documents"].map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Card elevation={6}
                sx={{ width: '100%', maxWidth: '80%', maxHeight: 650, overflow: 'auto' }}>
                <CardContent>
                   <ListItem sx={{position:'sticky',top:0,zIndex:999,bgcolor:theme.palette.background.default}}>
                   <Typography variant="h2" gutterBottom>Company Registration</Typography>
                   </ListItem>
                    <Divider />
                    <Typography variant="h6" mt={3}>Dealer Details</Typography>
                    <Grid container rowGap={3} columnSpacing={3} mt={1} width={'100%'}>
                        <Grid item xs={12} md={3}><TextField label="Partner Name" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Dealer Type" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Pan Number" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="GST Number" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Dealer Principle Name"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Dealer Principle Phone"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Dealer Principle Email"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Dealer Principle Pan/Adhaar" type="file" InputLabelProps={{ shrink: true }}></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Sales Person Full Name"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Sales Person Phone"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Sales Person Email"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Sales Person Pan/Adhaar"></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Sales Person Pan/Adhaar" type="file" InputLabelProps={{ shrink: true }}></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField fullWidth label="Employee Name"></TextField></Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" mt={3}>Address Details</Typography>
                    <Grid container rowGap={3} columnSpacing={3} mt={1}>
                        <Grid item xs={12}><TextField multiline rows={3} label="Dealership address" fullWidth></TextField></Grid>
                        <Grid item xs={12}><TextField multiline rows={3} label="Landmark" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Pin code" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="City" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="District " fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="State" fullWidth></TextField></Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" mt={3}>Account Details</Typography>
                    <Grid container rowGap={3} columnSpacing={3} mt={1} >
                        <Grid item xs={12} md={3}><TextField label="Account Name" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Bank Name" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Account type" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Account number" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="IFSC code " fullWidth></TextField></Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" mt={3}>KYC Details</Typography>
                    <Grid container rowGap={3} columnSpacing={3} mt={1} >
                        <Grid item xs={12} md={3}><TextField label="Account Name" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Bank Name" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Account type" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="Account number" fullWidth></TextField></Grid>
                        <Grid item xs={12} md={3}><TextField label="IFSC code " fullWidth></TextField></Grid>
                    </Grid>
                    <Box my={3}>
                        <Button variant="contained" fullWidth>Submit</Button>
                    </Box>

                </CardContent>
            </Card>
            <Box mt={3}/>
            <Typography variant="h5">Already Registered ? Login  <Link to="/company">here </Link></Typography>
           
        </Box>
    </Box>
}

export default CompanySignup;