import React, { useEffect, useState } from "react";
import { Avatar, CardContent, Grid, Link, TextField, Typography, CardMedia, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { clearError, setError } from "../../../../Redux/action-creators/errorAction";
import { clearSuccessMessage, setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { useTranslation } from "react-i18next";
import { generateOtp, otpValidation, validateUserByPhone } from "../../api/adminService";
import { getSmsResponse, getSmsVerificationResponse } from "../../../../Redux/Action"; // Import SMS action
import { setToken } from "../../../../Redux/action-creators/authAction";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import { gridSpacing } from "../../../../Redux/crmustomization/constant";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import employeSvg from "../../images/Employee_login.svg";

const AdminLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const navigate = useNavigate();
  const { control, getValues, handleSubmit, formState: { errors } } = useForm();
  const [step, setStep] = useState(1);
  const [otpSend, setOtpSend] = useState(false);
  const smsResponse = useSelector((store) => store.motorReducer.smsResponse);
  const successsResponseFrom = useSelector((store) => store.motorReducer.smsVerificationResponse)

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [resendDisabled, countdown]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
      setCountdown(60); // Reset countdown
    }
  }, [countdown]);

  const handleMobileNumber = async () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const mobileNumber = getValues("mobileNumber");
    const isNumber = () => !isNaN(Number(mobileNumber));
    if (isNumber && mobileNumber.toString().trim().length === 10) {
      validateUser(mobileNumber.toString().trim());
    } else {
      dispatch(setError(t("lmv.customer.invalidMobile")));
    }
  };

  const validateUser = async (phone) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const payload = { phone };
    setOtpSend(true);
    await validateUserByPhone(payload).then((response) => {
      if (response.data.status >= 400) {
        dispatch(setError(response.data.message));
        setOtpSend(false);
      } else {
        sendOtp(phone);
      }
    });
  };

  const sendOtp = async (phone) => {
    setOtpSend(true);
    dispatch(clearError());
    dispatch(clearSuccessMessage());

    const otpData = {
      phone,
      org_id: "100",
      method: "SEND",
      type: "E",
    };

    try {
      // const response = await dispatch(getSmsResponse(otpData));
      const response = await generateOtp(otpData);
      dispatch(setSuccessMessage('OTP generated successfully !'));
      setStep(2);
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setOtpSend(false);
    }
  };

  const handleResendOtp = async () => {
    const phone = getValues("mobileNumber");
    setResendDisabled(true);
    // await sendOtp(phone);
    const otpData = {
      phone,
      org_id: "100",
      agent_id: "1000",
      method: "RESEND",
      type: "E",
    };

    try {
      const response = await generateOtp(otpData);
      if (response.status === 200) {
        dispatch(setSuccessMessage(response?.data?.message));
        setStep(2);
      } else {
        dispatch(setError("Internal Server Error"))
      }
    } catch (error) {
      console.error("Error sending OTP:", error);

    } finally {
      setOtpSend(false);
    }
  };

  const onSubmit = async (data) => {
    setOtpSend(true);
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const method = 'VERIFY';
    const type = 'E'; // Type for customer (adjust as necessary)
    const verification_code = data.otp; // Using OTP from form data
    const phone = data.mobileNumber; // Phone number from form data
    // Dispatching the SMS verification response action
    try {
      const response = await otpValidation({ phone, verification_code, method, type });
      if (response.data?.success) {
        dispatch(setSuccessMessage(t('lmv.customer.loginSuccess')));
        localStorage.setItem('phone', data?.mobileNumber);
        localStorage.setItem('userType', 'employee');
        dispatch(setToken({ phone: data?.mobileNumber }));
        navigate('/employee');
      }
      else {
        dispatch(setError("Invalid OTP"));
      }
    } catch (error) {
      dispatch(setError("Internal Server Error"));
    } finally {
      setOtpSend(false)
    }



    // dispatch(getSmsVerificationResponse(phone, verification_code, method, type))
    // console.log(smsResponse, "llllllllllllllllllllllllllllllllllllll")
    // otpValidation({
    //   phone: data.mobileNumber,
    //   otp: data.otp,
    // }).then((response) => {
    //   if (response.status > 400) {
    //     dispatch(setError(t("lmv.serverError")));
    //     setOtpSend(false);
    //   } else {
    //     if (response.data.isOtpVerified ) {
    //       if (smsResponse.body.otp === verification_code) {
    //         dispatch(setSuccessMessage(t('lmv.customer.loginSuccess')));
    //         localStorage.setItem('jwtToken', response.data.data.token);
    //         localStorage.setItem('phone', response.data.data.phone);
    //         localStorage.setItem('userType', 'employee');
    //         dispatch(setToken(response.data.data));
    //         navigate('/employee');
    //       } else {
    //         // OTP mismatch
    //         dispatch(setError(t("lmv.invalidOtp"))); // Display error message
    //         setOtpSend(false); // Reset OTP sending state
    //       }
    //   }
    // }
    // });
  };

  const handlePhoneChange = () => setStep(1)


  return (
    <>
      <Grid container spacing={3} style={{ minHeight: "100vh" }}>
        <Grid component={Stack} item xs={false} sm={4} md={7}>
          <CardMedia
            sx={{ width: "400px", height: "400px", m: "auto" }}
            component="img"
            src={employeSvg}
            alt="Employee SVG"
          />
        </Grid>
        <Grid
          component={Stack}
          item
          xs={12}
          sm={8}
          md={5}
          elevation={6}
          sx={{ m: "auto" }}
        >
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MainCard
                content={false}
                border={true}
                sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
                boxShadow={true}
                darkTitle={true}
              >
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} alignItems="center">
                      <Avatar sx={{ m: 1, mx: "auto" }}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography textAlign="center" variant="h3">
                        Employee Login
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("lmv.requiredField"),
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: t("lmv.customer.invalidMobile"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("lmv.customer.mobileFormFieldLabel")}
                            variant="outlined"
                            margin="normal"
                            id="mobileNumber"
                            fullWidth
                            error={!!errors.mobileNumber}
                            disabled={step !== 1}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        )}
                      />
                      {errors.mobileNumber?.message && (
                        <Typography style={{ color: "red" }} variant="caption">
                          {errors.mobileNumber?.message}
                        </Typography>
                      )}
                    </Grid>
                    {step === 1 && (
                      <Grid item xs={12}>
                        <Button
                          disabled={otpSend}
                          variant="contained"
                          fullWidth
                          onClick={handleMobileNumber}
                        >
                          {!otpSend ? t("lmv.customer.proceed") : ""}
                          {otpSend && <CircularProgress size="20px" />}
                        </Button>
                      </Grid>
                    )}
                    {step === 2 && (
                      <>
                        <Grid item xs={12}>
                          <Controller
                            name="otp"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("lmv.requiredField") }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                label={t("lmv.customer.otp")}
                                type="text"
                                id="otp"
                                error={Boolean(errors.otp)}
                              />
                            )}
                          />
                          {errors.otp?.message && (
                            <Typography
                              style={{ color: "red" }}
                              variant="caption"
                            >
                              {errors.otp?.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            disabled={otpSend}
                            variant="contained"
                            type="submit"
                            fullWidth
                          >
                            {otpSend ? <CircularProgress size="20px" /> : "Submit OTP"}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="center">
                            Didn't receive the OTP?{" "}
                            <Link style={{ cursor: "pointer" }}
                              disabled={resendDisabled}
                              onClick={handleResendOtp}
                            >
                              Resend OTP {resendDisabled && `(${countdown}s)`}
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Link onClick={handlePhoneChange}>
                            Change Phone Number?
                          </Link>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </MainCard>
            </form>
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminLogin;
