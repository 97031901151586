import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { selfInspectionPut } from "../../api/adminService";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { useDispatch } from "react-redux";
import { doucmentsUpload } from "../OfflineForms/uploadOfflineFiles";

const TransactionEdit = ({
  selectedRow,
  setSelectedRow,
  openDialog,
  setOpenDialog,
  loading,
  setLoading,
  columns,
  setRows,
}) => {
  console.log(selectedRow, "xselect")
  console.log(columns, 'columns')
  const { handleSubmit, control, reset, clearErrors } = useForm();
  const dispatch = useDispatch();
  const status = [
    "APPROVED",
    "PENDING",
    "RECONCILIATION",
    "REJECTED",
    "CANCELED"
  ];
  const channels = [
    "Posp",
    "DIRECT",
    "Mis",
    "CUSTOMER",
  ];

  const [formLoading, setFormLoading] = React.useState(false);



  // Reset form values whenever selectedRow changes
  useEffect(() => {
    if (selectedRow) {
      reset(selectedRow);  // Reset form with selectedRow data
    }
  }, [selectedRow, reset]);



  const handleFileChange = async (file) => {
    console.log('file')
    if (file.type === "application/pdf") {
      try {
        setFormLoading(true)
        const response = await doucmentsUpload(file, file.type);
        console.log("Upload successful:", response);
        return response;
      } catch (error) {
        console.error("Upload error:", error);
      }
      finally {
        setFormLoading(false)
      }
    } else {
      // setError("file", {
      //   type: "manual",
      //   message: "Please select a valid PDF file.",
      // });
      // setSnackbarMessage("Please select a valid PDF file.");
    }
    // setSnackbarOpen(true);
    return null;
  };


  const onEditSubmit = async (data) => {
    // console.log(data, 'datt')
    if (!selectedRow) return;


    console.log(data, 'data')

    setLoading(true);
    try {
      const body = {
        org_id: 100,
        policy_number: data.policy_number,
        channels: data.channels,
        ...(data.channels === 'Posp' || data.channels === 'Mis' ? { insurer_name: data.customer_name } : {}),
        ...(data.channels === 'DIRECT' && { phone: data.phone }),
        agent_id: data.agent_id,
        policy_issue_date: data.policy_issue_date,
        policy_startdate: data.policy_startdate,
        policy_enddate: data.policy_enddate,
        policy_status: data.policy_status,
        reason:data.reason
      };
      if (!data.pdf_link && data.policy_copy) {
        body.pdf_link = data.policy_copy;
      }
      console.log(body, 'postbody')

      const res = await selfInspectionPut(body);

      if (res.status === 200) {
        setRows((prevRows) =>
          prevRows.map((row) => (row.id === selectedRow.id ? { ...row, ...data } : row))
        );
        dispatch(setSuccessMessage("Policy Updated Successfully!"));
      }
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };


  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };

  const fields = ['pdf_link', 'order_id', 'customer_id', 'channels', 'edit']

  return (
    <Dialog open={openDialog} onClose={handleDialogClose}>
      <DialogTitle
        textAlign="center"
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textDecoration: "underline",
        }}
      >
        Edit Policy
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onEditSubmit)}>
          <Grid container columnSpacing={3} mt={1} rowSpacing={2}>
            {/* {columns.map((column) =>
              !fieldsNotToUpdate.includes(column.field) &&
                selectedRow &&
                selectedRow[column.field] !== null &&
                selectedRow[column.field] !== undefined &&
                selectedRow[column.field] !== "" ? (
                <Grid item xs={6} key={column.field}>
                  <Controller
                    name={column.field}
                    control={control}
                    defaultValue={selectedRow[column.field] || ""}
                    render={({ field }) => {
                      if (column.field === "policy_status") {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            select
                          >
                            {plans.map((plan) => (
                              <MenuItem key={plan} value={plan}>
                                {plan}
                              </MenuItem>
                            ))}
                          </TextField>
                        );
                      }

                      if (column.field.toLowerCase().includes("date")) {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        );
                      }

                      return (
                        <TextField
                          {...field}
                          label={column.headerName}
                          fullWidth
                          disabled={
                            column.field === "customer_id" ||
                            column.field === "order_id"
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              ) : null
            )} */}
            {
              selectedRow && columns.map((column) => {
                return (!fields.includes(column.field) && selectedRow[column?.field] !== '' && selectedRow[column?.field]) && <Grid item xs={6} key={column.field}>
                  <Controller
                    name={column.field}
                    control={control}
                    defaultValue={selectedRow[column?.field] || ""}
                    render={({ field }) => {
                      if (column.field === "policy_status") {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            select
                          >
                            {status.map((status) => <MenuItem children={status} key={status} value={status} />)}
                          </TextField>
                        );
                      }
                      if (column.field === "channels") {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            select
                          >
                            {channels.map((channels) => <MenuItem children={channels} key={channels} value={channels} />)}
                          </TextField>
                        );
                      }
                      if (column.field.toLowerCase().includes("date")) {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        );
                      }
                      return (
                        <TextField
                          {...field}
                          label={column.headerName}
                          fullWidth
                        />
                      );
                    }}
                  />
                </Grid>
              })
            }

            {!selectedRow?.pdf_link && <Grid item xs={6}>
              <Controller
                name="policy_copy"
                control={control}
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      type="file"
                      inputProps={{ accept: "application/pdf" }}
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (file) {
                          clearErrors("file");
                          const response = await handleFileChange(file);
                          if (response) {
                            field.onChange(response.Location);
                          }
                        }
                      }}
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ""}
                      label="Upload Policy Copy (PDF)"
                      InputLabelProps={{ shrink: true }}
                    />
                  </>
                )}
              />
            </Grid>}
          </Grid>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleDialogClose}
              color="primary"
              variant="contained"
              disabled={loading || formLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading || formLoading}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionEdit;