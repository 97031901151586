import { Button, Grid, TextField, MenuItem, Snackbar, Slide, InputAdornment } from "@mui/material";
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import OtpPopup from "./OtpPopup";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";
import { clearError, setError } from '../../../../Redux/action-creators/errorAction';
import { clearSuccessMessage, setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import Autocomplete from '@mui/material/Autocomplete';
import { fetchEmployees, fetchAllAgentsIdAndName } from "../../api/pospService";
import axios from "axios";



const PersonalDetails = (props) => {
    const { register, handleSubmit, trigger, formState: { errors, isSubmitted }, setValue, getValues, control } = useForm()
    const [otpDialogOpen, setOtpDialogOpen] = useState(false);
    const [isLoaderTrue, setIsLoaderTrue] = useState(false)
    const [isDataLoaded, setDataLoaded] = useState(true)
    const dispatch = useDispatch();
    const [currentReference, setCurrentReference] = useState('personal')
    const [agentsData, setAgentsData] = useState([])
    const [filterAgentData, setFilterAgentData] = useState([])
    const [employeesData, setEmployyeesData] = useState([])
    const [filterEmployeesData, setFilterEmployeesData] = useState([])


    useEffect(() => {
        // it is usefull when user click back button
        for (let name in props.personalData) {
            setValue(name, props.personalData[name])
            if (name === 'reference') {
                setCurrentReference(props.personalData[name])
            }
        }
    }, [])

    useEffect(() => {
        const callEmployeeAndAgentApi = async () => {
            dispatch(clearError());
            dispatch(clearSuccessMessage());
            setDataLoaded(true)
            let agentUpdateData
            let employeeUpdateData
            try {
                const response = await fetchEmployees()
                console.log(response , 'response')
                if (response.status === 200) {
                    // const updatedData = response?.data?.data?.map((eachValue) => ({ label: `${eachValue?.emp_name} (${eachValue?.empid})`, emp_id: eachValue?.empid }))
                    employeeUpdateData = response?.data?.map((eachValue) => ({ label: `${eachValue?.emp_name} (${eachValue?.empid})`, emp_id: eachValue?.empid }))
                    setEmployyeesData(employeeUpdateData)
                }

                const agentResp = await fetchAllAgentsIdAndName()
                if (agentResp.status === 200) {
                    agentUpdateData = agentResp?.data?.map((eachValue) => ({ label: `${eachValue?.agent_name} (${eachValue?.agent_id})`, emp_id: eachValue?.agent_id }))
                    setAgentsData(agentUpdateData)
                }

                filterReferenceId(props?.personalData?.reference_id, employeeUpdateData, agentUpdateData, props.personalData['reference'])

            } catch (e) {
                console.log(e.message, 'error')
                dispatch(setError('Internal server error'))
            }
            setDataLoaded(false)
        }

        callEmployeeAndAgentApi()
    }, [])


    const sendOtp = async () => {
        dispatch(clearError());
        dispatch(clearSuccessMessage());

        setIsLoaderTrue(true)
        try {

            const verifyUser = await fetch('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/validateuser', {
                method: 'POST',
                body: JSON.stringify({
                    enrollType: "SIGNUP",
                    phone: getValues().mobile
                })
            })

            const jsonOfVerifyUser = await verifyUser.json()

            if (jsonOfVerifyUser.signUpRequestFlag) {

                const response = await fetch('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/generateOTP', {
                    method: 'POST',
                    body: JSON.stringify({
                        "enrollType": "SIGNUP",
                        "phone": getValues().mobile
                    })
                })
                const data = await response.json()

                if (response.ok) {
                    dispatch(setSuccessMessage(data.message))


                    // open popup of otp enter fileds
                    setOtpDialogOpen(true)
                }
                else {
                    dispatch(setError(data.message))

                }

            } else {
                dispatch(setError(jsonOfVerifyUser.message))
            }


        } catch (e) {
            dispatch(setError('Something Went Wrong...'))
        }

        setIsLoaderTrue(false)
    }

    const submitHandler = (e) => {
        const { clickNextBtn } = props
        clickNextBtn(getValues())
    }

    const changeHandler = async (e) => {

        const { name, value } = e.target

        if (name === 'pan') {
            setValue(name, value.slice(0, 10).toUpperCase())

        } else if (name === 'mobile') {
            setValue(name, value.slice(0, 10))
        } else if (name === 'aadhaar') {
            setValue(name, value.slice(0, 12))
        } else if (name === 'email') {
            setValue(name, value)
        } else if (name === 'reference') {
            setCurrentReference(value)
            setValue(name, value.toUpperCase())
            setValue('reference_id', null)
            setFilterEmployeesData([])
            setFilterAgentData([])
        }
        else {
            setValue(name, value.toUpperCase())
        }

        { isSubmitted && await trigger(name) }

    }

    const filterReferenceId = (value, employeesData, agentsData, currentReference) => {

        if (value?.length === 0 || value === undefined) {
            setFilterEmployeesData([])
            setFilterAgentData([])
        } else {
            if (currentReference === 'employee') {
                const updatedData = employeesData.filter((eachValue) => eachValue?.label?.toUpperCase()?.includes(typeof value !== 'number' ? value?.toUpperCase() : value))
                setFilterEmployeesData(updatedData)
            }

            if (currentReference === 'agent') {
                const updatedData = agentsData.filter((eachValue) => eachValue?.label?.toUpperCase()?.includes(typeof value !== 'number' ? value?.toUpperCase() : value))
                setFilterAgentData(updatedData)
            }
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit(sendOtp)} style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={4}>

                    {/* first name */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="First Name" color="primary" name="firstname" autoComplete="off"
                            {...register('firstname', {
                                required: 'Please Enter Your First Name',
                                pattern: {
                                    value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                                    message: 'Invalid First Name'
                                }
                            })}
                            onChange={changeHandler}
                            error={!!errors.firstname}
                            helperText={errors.firstname ? errors.firstname.message : ''}
                        />
                    </Grid>

                    {/* lastname */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Last Name" color="primary" name="lastname" autoComplete="off"
                            {...register('lastname', {
                                required: 'Please Enter Your Last Name',
                                pattern: {
                                    value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                                    message: 'Invalid Last Name'
                                }
                            })}
                            onChange={changeHandler}
                            error={!!errors.lastname}
                            helperText={errors.lastname ? errors.lastname.message : ''}
                        />
                    </Grid>

                    {/* pan */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Pancard Number" color="primary" name="pan" autoComplete="off"

                            {...register('pan', {
                                required: 'Please Enter Pan Number',
                                pattern: {
                                    value: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
                                    message: 'Invalid Pan Number'
                                }
                            })}
                            onChange={(e) => changeHandler(e)}
                            error={!!errors.pan}

                            helperText={errors.pan ? errors.pan.message : ''}
                        />
                    </Grid>

                    {/* mobile */}
                    <Grid item xs={12} md={4}>
                        <TextField type="number" fullWidth label="Contact Number" color="primary" name="mobile" autoComplete="off"
                            {...register('mobile', {
                                required: 'Please Enter Mobile Number',
                                pattern: {
                                    value: /^[6-9]\d{9}$/,
                                    message: 'Invalid mobile Number'
                                }
                            })}
                            onChange={changeHandler}
                            error={!!errors.mobile}
                            helperText={errors.mobile ? errors.mobile.message : ''}
                        />
                    </Grid>

                    {/* gender */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Select Gender" select color="primary" name="gender" defaultValue={props.personalData.gender || ''}
                            {...register('gender', {
                                required: 'Please Select gender'
                            })}
                            onChange={changeHandler}
                            error={!!errors.gender}
                            helperText={errors.gender ? errors.gender.message : ''}
                        >
                            <MenuItem value="M">Male</MenuItem>
                            <MenuItem value="F">Female</MenuItem>
                        </TextField>
                    </Grid>

                    {/* birthdate */}
                    <Grid item xs={12} md={4}>
                        <TextField type="date" fullWidth label="DOB" color="primary" name="birthdate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register('birthdate', {
                                required: 'Please Enter Date of Birth',
                                pattern: {
                                    value: /^\d{4}-\d{2}-\d{2}$/, // Adjust pattern as needed for your date format
                                    message: 'Invalid Date Format (YYYY-MM-DD)',
                                },
                            })}
                            onChange={changeHandler}
                            error={!!errors.birthdate}
                            helperText={errors.birthdate ? errors.birthdate.message : ''}
                        />
                    </Grid>

                    {/* aadhar */}
                    <Grid item xs={12} md={4}>
                        <TextField type="number" fullWidth label="Aadhar Number" color="primary" name="aadhaar" autoComplete="off"
                            {...register('aadhaar', {
                                required: 'Please Enter Aadhar Number',
                                pattern: {
                                    value: /^[1-9]{1}[0-9]{3}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
                                    message: 'Invalid Aadhar Number'
                                }
                            })}
                            onChange={changeHandler}
                            error={!!errors.aadhaar}
                            helperText={errors.aadhaar ? errors.aadhaar.message : ''}
                        />
                    </Grid>

                    {/* email */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="E-Mail" color="primary" name="email" autoComplete="off"
                            {...register('email', {
                                required: 'Please Enter Email',
                                pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message: 'Invalid email'
                                }
                            })}
                            onChange={changeHandler}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                    </Grid>

                    {/* educational Details */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Select Qualification" select color="primary" name="educationalDetails" defaultValue={props.personalData.educationalDetails || ''}
                            {...register('educationalDetails', {
                                required: 'Please Select Educational Details'
                            })}
                            error={!!errors.educationalDetails}
                            helperText={!!errors.educationalDetails ? errors.educationalDetails.message : ''}
                            onChange={changeHandler}
                        >
                            <MenuItem value="UNDER GRADUATE">Under Graduate</MenuItem>
                            <MenuItem value="GRADUATE">Graduate</MenuItem>
                            <MenuItem value="POST GRADUATE">Post Graduate</MenuItem>
                        </TextField>
                    </Grid>

                    {/* reference */}
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Select Reference" select color="primary" name="reference" defaultValue={props.personalData.reference || ''}
                            {...register('reference', {
                                required: 'Please Select Reference'
                            })}
                            onChange={changeHandler}
                            error={!!errors.reference}
                            helperText={errors.reference ? errors.reference.message : ''}
                        >
                            <MenuItem value="personal">Personal</MenuItem>
                            <MenuItem value="employee">Employee</MenuItem>
                            <MenuItem value="agent">Agent</MenuItem>
                        </TextField>

                    </Grid>

                    {/* reference id */}
                    {currentReference !== 'personal' && (
                        <Grid item xs={12} md={4}>

                            <Controller
                                name="reference_id"
                                control={control}
                                // defaultValue={props.personalData.reference_id || ''}
                                rules={{ required: 'Please select a reference id' }}
                                render={({ field }) => (

                                    <Autocomplete
                                        disablePortal
                                        options={currentReference === 'employee' ? filterEmployeesData : filterAgentData}
                                        fullWidth
                                        defaultValue={props?.personalData?.reference_id || ''}
                                        value={currentReference === 'employee' ? filterEmployeesData.find(option => option.emp_id === field.value) || null : filterAgentData.find(option => option.emp_id === field.value) || null}
                                        onChange={(event, newValue) => {
                                            // Update the form state and trigger revalidation
                                            setValue('reference_id', newValue ? newValue.emp_id : '', { shouldValidate: true });
                                        }}
                                        onInputChange={(event, newValue) => {
                                            filterReferenceId(newValue, employeesData, agentsData, currentReference)
                                        }}
                                        loading={isDataLoaded}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                // slotProps={{
                                                //     input: {
                                                //         ...params.InputProps,
                                                //         endAdornment: (
                                                //             <React.Fragment>
                                                //                 {true ? <CircularProgress color="inherit" size={20} /> : null}
                                                //                 {params.InputProps.endAdornment}
                                                //             </React.Fragment>
                                                //         ),
                                                //     },
                                                // }}
                                                label="Reference Id"
                                                error={!!errors.reference_id}
                                                helperText={errors.reference_id ? errors.reference_id.message : ''}
                                            />
                                        }
                                    />
                                )}
                            />


                        </Grid>
                    )}

                </Grid>

                {isLoaderTrue ? (
                    <Button variant="contained" color="primary" sx={{ marginTop: '20px', alignSelf: 'end' }} type="button">
                        <CircularProgress color="inherit" />
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" sx={{ marginTop: '20px', alignSelf: 'end' }} type="submit">Next</Button>
                )}

            </form>
            <OtpPopup mobile={getValues().mobile} submitHandler={submitHandler} open={otpDialogOpen} onClose={() => setOtpDialogOpen(false)} />
        </>
    )
}

export default PersonalDetails