import {
  Box,
  Divider,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  TextField,
  MenuItem,
  CardContent,
  Card,
  Grid,
  LinearProgress,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NavigateNext,
  Business,
  Refresh,
  Loop,
  Paid
} from "@mui/icons-material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getPremium } from "../api/adminService";
import MainCard from "../../posp/ui-component/cards/MainCard";
import { gridSpacing } from "../../../Redux/crmustomization/constant";
import { useSelector } from "react-redux";

const AdminHome = () => {
  const empId = useSelector((state) => state.adminReducer.loginData.empid);
  const role = useSelector((state) => state.adminReducer.loginData.Role);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState(null)
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    period: "WEEKLY",
    bussiness: "NEW_BUSSINESS",
    empid: empId
  });



  // max date yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = yesterday.toISOString().split("T")[0];


  // make api call for getting total premium based on type
  useEffect(() => {
    let res = async () => {
      try {
        setLoading(true);
        if (filters.fromDate !== '' && filters.toDate !== '') {
          const payload = {
            ...filters,
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            period: ''
          }
          const res = await getPremium(payload);
          console.log(res.data)
          setData(res?.data)

        } else {
          if (filters.fromDate !== '' || filters.toDate !== '') {

          } else {
            const payload = {
              ...filters,
              fromDate: '',
              toDate: '',
              period: filters.period
            }
            const res = await getPremium(payload);
            console.log(res.data)
            setData(res?.data)
          }

        }
      } catch (error) {
        console.log('errr', error)
      } finally {
        setLoading(false);
      }
    };
    res();
  }, [filters]);




  const reset = () => setFilters({
    fromDate: "",
    toDate: "",
    period: "WEEKLY",
    bussiness: "NEW_BUSSINESS",
    empid: 70000
  })

  const breadcrumbs = [
    <Link
      component={Typography}
      variant="h5"
      underline="hover"
      key="1"
      color="inherit"
      href="/"
    >
      Dashboard
    </Link>,
    <Typography key="3" color="#23a8fa" variant="h5">
      {role}
    </Typography>,
  ];

  const cardArray = [
    {
      title: "NEW_BUSSINESS",
      icon: <Business />,
      des: "+55% This Month Business",
    },
    { title: "ROLL_OVER", icon: <Refresh />, des: "+55% This Month Rollover" },
    { title: "RENEWAL", icon: <Loop />, des: "+55% This Month Renewal" },
    { title: "REVENUE", icon: <Paid />, des: "+55% This Month Revenue" },
  ];






  const handleFilters = (event, newValue) => {
    const { name, value } = event.target;
    const data_name = event?.currentTarget?.dataset?.name;
    if (name === 'period') {
      setFilters(prev => ({ ...prev, [name]: value, toDate: '', fromDate: '' }))
    }
    if (name === 'fromDate') {
      setFilters(prev => ({ ...prev, [name]: value }))
    }
    if (name === 'toDate') {
      setFilters(prev => ({ ...prev, [name]: value }))
    }
    if (data_name === 'bussiness') {
      setFilters(prev => ({ ...prev, bussiness: newValue }))
    }
  }





  const permiumChartStyles = {
    chart: {
      type: "column",
    },
    title: {
      text: "Total Revenue",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    colors: [
      "#00CED1", // Dark Turquoise
      "#FFD700", // Gold
      "#8A2BE2", // Blue Violet
      "#FF6347", // Tomato
      "#32CD32", // Lime Green
    ],
    xAxis: {
      categories: data ? data.chartData.categories : [],
    },
    yAxis: {
      title: {
        text: "Total Revenue",
      },
      min: 0,

      max: null,
    },
    series: data ? data.chartData.series : []
  };

  const options2 = {
    chart: {
      type: "column",
    },
    title: {
      text: `${"weekly"}ly Sales`,
      align: "left",
    },
    subtitle: {
      text: `This ${"weekly"} Renewals`,
      align: "left",
    },
    xAxis: {
      categories: data ? data.graphData.categories : [],
    },
    colors: [
      "#00CED1", // Dark Turquoise
      "#FFD700", // Gold
      "#8A2BE2", // Blue Violet
      "#FF6347", // Tomato
      "#32CD32", // Lime Green
    ],
    yAxis: {
      title: {
        text: "Number of Renewals",
      },
      min: 0,
    },
    series: data ? data.graphData.series : [],
  };


  const piechart1 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: true,
      type: "pie",
    },
    title: {
      text: "Product Claims",
      align: "left",
    },
    colors: [
      "#00CED1", // Dark Turquoise
      "#FFD700", // Gold
      "#8A2BE2", // Blue Violet
      "#FF6347", // Tomato
      "#32CD32", // Lime Green
    ],
    subtitle: {
      text: "Product Wise Claims",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "40%",
        borderRadius: 10,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          distance: -30,
          format: "{point.percentage:.1f}%",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: data ? data.pieData.data : []
      }
    ],
  };

  return (
    <Box>
      <Stack
        sx={{ py: 2, bgcolor: "#ecf9ff69" }}
        justifyContent={"center"}
        direction="row"
      >
        <Stack sx={{ textAlign: "center", color: "#23a8fa" }} spacing={2}>
          <Typography variant="body2" fontWeight={700}>
            Welcome
          </Typography>
          <Typography variant="h4" textTransform={"uppercase"} fontWeight={600}>
            {/* {profile} */}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Box
        component={Stack}
        spacing={2}
        px={4}
        direction={"row"}
        justifyContent="space-between"
        py={2}
        alignItems={"center"}
      >
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack direction="row" columnGap={3}>
          <Stack direction="row" columnGap={1}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              value={filters['fromDate']}
              onChange={handleFilters}
              name="fromDate"
              label="From"
              type="date"
              size="small"
            />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              value={filters['toDate']}
              onChange={handleFilters}
              name="toDate"
              label="To"
              type="date"
              size="small"
              inputProps={{
                max: formattedYesterday, // Set yesterday as the maximum date
              }}
            />
            <TextField
              name="period"
              size="small"
              sx={{ minWidth: "120px" }}
              label="Sort by"
              value={filters['period']}
              onChange={handleFilters}
              select
              data-name={"period"}
            >
              <MenuItem value="WEEKLY">Week</MenuItem>
              <MenuItem value="MONTHLY">Month</MenuItem>
              <MenuItem value="YEARLY">Year</MenuItem>
            </TextField>
            <IconButton onClick={reset} >
              <Refresh color="primary" />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Stack
        sx={{ px: 4, py: 3, flexDirection: { md: 'row' } }}
        columnGap={3}
        rowGap={1}
      >
        {cardArray.map((item, i) => (
          <Card
            name={item.title}
            key={i}
            sx={{
              cursor: "pointer",
              flex: 1,
              backgroundColor: filters['bussiness'] === item.title ? "#23a8fa" : "#0000000a",
              color: filters['bussiness'] === item.title ? "#fff" : "5f5f5f",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transition: "0.3s",
              "&:hover": {
                boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
              },
            }}
            onClick={(event) => handleFilters(event, item.title)}
            data-name={"bussiness"}
          >
            <CardContent
              component={Stack}
              spacing={1}
              sx={{ paddingBottom: "0px" }}
            >
              <Typography
                variant="h5"
                component="h2"
                color={"inherit"}
              >
                <Box component='span' mr={1}>
                  {item.icon}
                </Box>
                {item.title}
              </Typography>
              <Typography variant="body2" component="p">
                {item.des}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Stack>

      {loading ? <LinearProgress /> : <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <MainCard content={false}>
                <CardContent>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={permiumChartStyles}
                  />
                </CardContent>
              </MainCard>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12}>
                  <MainCard content={false}>
                    <CardContent>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options2}
                      />
                    </CardContent>
                  </MainCard>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MainCard content={false}>
                    <CardContent>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={piechart1}
                      />
                    </CardContent>
                  </MainCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>}
    </Box>
  );
};

export default AdminHome;
