import axios from "axios";
import { common_api, crm_api } from '../../../Config'

export const validatePospUserByPhone = (payload) => {
    return axios.post(crm_api + '/Posp/v1/user/validateuser', payload);
};


export const getStudyMaterial = () => {
    return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/training_modules.json')
}


export const generateOtp = (payload) => {
    // return axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/generateOTP', payload); 
    return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/messages/v1/sms', payload);
};


export const otpValidation = (payload) => {
    // return axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/validateOTP', payload);
    return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/messages/v1/sms', payload);
};

export const fetchPospData = (phone) => {
    return axios.get(crm_api + `/Posp/v1/profileDetails/${phone}`)
    // return axios.get(`http://localhost:5000/Posp/v1/profileDetails/${phone}`)
}


export const updatePospProfileData = (phone, payload) => {
    return axios.put(crm_api + `/Posp/v1/profileDetails/${phone}`, payload);
};

export const updatePospProfileSetting = (agent_id, payload) => {
    return axios.put(crm_api + `/Posp/v1/profilesettings/${agent_id}`, payload);
};

export const addCustomer = (playload) => {
    return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/insurance/crm/v1/org/agent/customer', playload)
}

export const addOfflinePolicy = (payload) => {
    // return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/insurance/policy', payload);
    return axios.post(common_api + '/insurance/policy', payload);

}


export const getPospBankAccount = (agent_id) => {
    return axios.get(crm_api + `/Posp/v1/bankAccountInfo/${agent_id}`);
};

export const postPospBankDetails = (agent_id, payload) => {
    return axios.put(crm_api + `/Posp/v1/bankAccountInfo/${agent_id}`, payload);
};

export const getPospSalesData = (agent_id) => {
    return axios.get(crm_api + `/Posp/v1/salesinfo/${agent_id}`);
};
export const getPospSalesBarchartData = (playload) => {
    return axios.post(crm_api + '/Posp/v1/salesinfo', playload)

}

export const getPaymentLogData = (agent_id) => {
    return axios.get(`https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/Posp/v1/payemntlogs/${agent_id}`);
};


export const getPoliciesDataById = (agent_id) => {
    return axios.get(crm_api + `/Posp/v1/policy/${agent_id}`);
    // return axios.get(`http://localhost:5000/Posp/v1/policy/${agent_id}`);
};

export const getLastClaimID = () => {
    return axios.get(crm_api + '/Posp/v1/claims');
    // return axios.get('http://localhost:5000/posp/v1/claims')
}

export const postClaimDetail = (payload) => {
    return axios.post(crm_api + '/Posp/v1/claims', payload);
    // return axios.post('http://localhost:5000/posp/v1/claim', payload); // local
};

export const UpdateClaimDetail = (payload) => {
    return axios.put(crm_api + '/posp/v1/claims', payload);
    // return axios.post('http://localhost:5000/posp/v1/claim', payload); // local
};

export const getClaimsByAgentId = (agent_id) => {
    return axios.get(crm_api + '/Posp/v1/claims/' + agent_id);
    // return axios.get('http://localhost:5000/posp/v1/claim/' + agent_id);
};


export const getPospDocuments = (agent_id) => {
    // return axios.get(`https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/document/upload/${agent_id}`);
};

export const updatePospDocument = (agent_id , payload) => {
    return axios.put(crm_api + `/Posp/v1/document/upload/${agent_id}` , payload)
    // return axios.put(`http://localhost:5000/Posp/v1/document/upload/${agent_id}`,payload);
};

export const fetchQuestions = async () => {
    return axios.get(crm_api + `/Posp/examination`);
};

export const fetchQuestionsAPI = async () => {
    const response = await axios.get(crm_api + `/Posp/examination`);
    //   const response = await axios.get(`http://localhost:5000/Posp/v1/questions`); 
    return response?.data;
};

export const submitAnswersAPI = async (responses, agentId) => {

    const payload = { questions: responses, agent_id: agentId };
    const response = await axios.post(crm_api + '/Posp/examination', payload);
    //   const response = await axios.post('http://localhost:5000/Posp/v1/valuation/examination', payload);
    return response?.data;
};

export const fetchEmployees = async () => {
    return await axios.get(crm_api + '/admin/allemployesdata')
    // return await axios.get('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/admin/usermanagement/0')
}

export const fetchLastAgentId = async () => {
    return await axios.get(crm_api + '/Posp/agentidgenerator')
}

export const fetchAllAgentsIdAndName = async () => {
    return await axios.get(crm_api + '/Posp/poplist')
}

export const fetchCustomerData = async (phone) => {
    return await axios.get('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/profileDetails/' + phone)
}

export const getLifePlains = () => {
    return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/admin/lifeplans.json')
}