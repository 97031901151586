import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { doucmentsUpload } from "../../functions/upload-doc";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { common_api } from "../../../../Config";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators//successAction";
import {
  clearError,
  setError as setErrorMessage,
} from "../../../../Redux/action-creators/errorAction";
import { addOfflinePolicy, getLifePlains } from "../../api/pospService";
import CustomerOfflineForm from "./CustomerForm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";


const LifeOfflineForm = () => {
  const dispatch = useDispatch();
  const pospData = useSelector((state) => state?.posp?.data);
  
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    control,
    setValue,
    setError,
    getValues,
  } = useForm({ defaultValues: { document_type: "PAN", city: null } });
  const [loading, setLoading] = useState(false);
  const insurerName = watch("insurerName");
  const planType = watch("planType");
  const issueDate = watch("policy_issuedate");
  const theme = useTheme();
  const [plans, setPlans] = useState({});
  const [policyDoc, setPolicyDocu] = useState("");
  const [policyDocError, setPolicyDocError] = useState("");

  const getPlans = async () => {
    const plans1 = await getLifePlains();
    console.log(plans1, "plans one");
    setPlans(plans1.data);
  };

  useEffect(() => {
    getPlans();
  }, []);

  

  const fileChangeHandler = (event) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const { files } = event.target;
    console.log("called");
    if (/\.(pdf)$/i.test(files[0]?.name)) {
      let file_size = files[0].size;
      let fileSize = parseInt(file_size / 1024);

      if (fileSize <= 5120) {
        setPolicyDocu(files[0]);
        setPolicyDocError("");
      } else {
        dispatch(setErrorMessage("upload maximum file size is 5MB"));
        setPolicyDocu(null);
        event.target.value = null;
      }
    } else {
      dispatch(
        setErrorMessage("Invalid file type. Please upload a .pdf file.")
      );
      event.target.value = null;
      setPolicyDocu(null);
    }
  };


  const CallPostApi = async (data) => {
    const insurer_details = {
      insurer_name: data?.customer_name,
      insurer_address: data?.address,
      insurer_state: data?.state,
      insurer_city: data?.city,
      insurer_pincode: data?.pincode,
      insurer_phone: data?.phone,
      insurer_email: data?.email,
      insurer_dob: data?.dob,
      insurer_gender: data?.gender,
      insurer_marital_status: data?.marital_status,
      insurer_document_type: data?.document_type,
      insurer_document_number: data?.document_number,
    };
    // const customer_details = {
    //     customer_name: data?.name,
    //     address: data?.address,
    //     state: data?.state,
    //     city: data?.city,
    //     pincode: data?.pincode,
    //     phone: data?.phone,
    //     email: data?.email,
    //     dob: data?.dob === '' ? null : data?.dob,
    //     gender: data?.gender,
    //     marital_status: data?.marital_status,
    //     document_type: data?.documentType === '' ? null : data?.documentType,
    //     document_number: data?.documentType === '' ? null : data?.documentNumber
    // }

    const reqBody = {
      org_id: 100,
      agent_id: pospData?.agent_id,
      ...insurer_details,
      application_id: `'${data?.application_id} (OFFLINE)`,
      product_type: "LIFE",
      product_subtype: data?.planType,
      policy_type: "NEW_BUSSINESS",
      policy_sub_type: "INDIVIDUAL",
      policy_issue_date:
        data?.policy_issuedate !== "" ? data?.policy_issuedate : null,
      provider: data?.insurerName,
      plan_name: data?.planName,
      channels: "Posp",
      policy_number: "'" + data?.application_id,
      policy_startdate: data?.policy_startdate,
      policy_enddate: data?.policy_enddate,
      sum_insured: Number(data?.cover_amount),
      premium_without_gst: Number(data?.netpremium),
      premium_with_gst: Number(data?.grosspremium),
      pdf_link: data?.pdf_link || null,
      policy_status: "RECONCILIATION",
      tenure: data?.cover_,
      policy_payment_term: data?.ppt,
      payment_term: data?.pt,
      payment_mode: data?.payment_mode,
    };

    console.log(reqBody, "req body");

    try {
      setLoading(true);
      const policyRes = await addOfflinePolicy(reqBody);
      if (policyRes.status === 200) {
        dispatch(setSuccessMessage("Policy Added Successfully !"));
        reset();
        setPolicyDocu("");
      } else {
        dispatch(setErrorMessage("Something Went Wrong..."));
      }
    } catch (error) {
      console.log(error.message);
      dispatch(setErrorMessage("Internal Server Error"));
    } finally {
      setLoading(false);
    }
  };

  const submitForm = async (data) => {
    console.log(data, "data");
    if (issueDate) {
      if (policyDoc) {
        const document_url = await doucmentsUpload(
          policyDoc,
          policyDoc?.type,
          "Policy_doc"
        );
        if (document_url?.Location) {
          data["pdf_link"] = document_url?.Location;
          CallPostApi(data);
        } else {
          dispatch(setErrorMessage("Document Upload Fail..."));
        }
      } else {
        setPolicyDocError("Please Upload Policy Document...");
      }
    } else {
      // setPolicyDocError('')
      CallPostApi(data);
    }
  };

  return (
    <MainCard title={"Life Form"}>
      <Grid container>
        <form onSubmit={handleSubmit(submitForm)}>
          {/* customer details */}
          <Grid item xs={12}>
            <CustomerOfflineForm
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              watch={watch}
              getValues={getValues}
              product={'LIFE'}
            />
          </Grid>

          {/* policy Details */}
          <Grid item xs={12}>
            <Grid container rowSpacing={4} mt={0} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Policy Details</Typography>
                
              </Grid>

              {/* Insurer company */}
              <Grid item xs={3}>
                <FormControl fullWidth error={!!errors.insurerName}>
                  <InputLabel id="simple-select-label">
                    Insurer company <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Controller
                    name="insurerName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }} // Validation rule
                    render={({ field }) => (
                      <Select {...field} label="Insurer company">
                        {Object.keys(plans).map((cmpny) => {
                          return <MenuItem value={cmpny}>{cmpny}</MenuItem>;
                        })}
                      </Select>
                    )}
                  />
                  {errors.insurerName && (
                    <FormHelperText>
                      {errors.insurerName.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* plan type */}
              <Grid item xs={3}>
                <FormControl fullWidth error={!!errors.planType}>
                  <InputLabel id="simple-select-label">
                    Plan Type <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Controller
                    name="planType"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }} // Validation rule
                    render={({ field }) => (
                      <Select {...field} label="Plan Type">
                        {insurerName &&
                          Object.keys(plans[insurerName || ""]).map(
                            (planType) => {
                              return (
                                <MenuItem value={planType}>{planType}</MenuItem>
                              );
                            }
                          )}
                      </Select>
                    )}
                  />
                  {errors.insurerName && (
                    <FormHelperText>
                      {errors.insurerName.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* plan name */}
              <Grid item xs={3}>
                <FormControl fullWidth error={!!errors.planName}>
                  <InputLabel id="simple-select-label">
                    Plan Name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Controller
                    name="planName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }} // Validation rule
                    render={({ field }) => (
                      <Select {...field} label="Plan Name">
                        {planType &&
                          plans[insurerName][planType].map((planType) => {
                            return (
                              <MenuItem value={planType}>{planType}</MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  {errors.planName && (
                    <FormHelperText>{errors.planName.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* application id */}
              <Grid item xs={3}>
                <TextField
                  type="number"
                  inputMode="numeric"
                  {...register("application_id", {
                    required: "This field is Required",
                  })}
                  error={!!errors.application_id}
                  helperText={
                    errors.application_id ? errors.application_id.message : ""
                  }
                  fullWidth
                  label={
                    <>
                      Proposal Number <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
              </Grid>

              {/* cover amount */}
              <Grid item xs={3}>
                <TextField
                  type="number"
                  inputMode="numeric"
                  {...register("cover_amount", {
                    required: "This field is Required",
                  })}
                  error={!!errors.cover_amount}
                  helperText={
                    errors.cover_amount ? errors.cover_amount.message : ""
                  }
                  fullWidth
                  label={
                    <>
                      Sum Insured <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
              </Grid>

              {/* cover */}
              <Grid item xs={3}>
                <TextField
                  {...register("cover_", {
                    required: "This field is Required",
                  })}
                  error={!!errors.cover_}
                  helperText={errors.cover_ ? errors.cover_.message : ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <>
                      Cover till Age <span style={{ color: "red" }}>*</span>
                    </>
                  }
                ></TextField>
              </Grid>

              {/* ppt */}
              <Grid item xs={3}>
                <TextField
                  {...register("ppt", { required: "This field is Required" })}
                  error={!!errors.ppt}
                  helperText={errors.ppt ? errors.ppt.message : ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <>
                      Policy payment term (ppt){" "}
                      <span style={{ color: "red" }}>*</span>
                    </>
                  }
                ></TextField>
              </Grid>

              {/* pt */}
              <Grid item xs={3}>
                <TextField
                  {...register("pt", { required: "This field is Required" })}
                  error={!!errors.pt}
                  helperText={errors.pt ? errors.pt.message : ""}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <>
                      payment term (pt) <span style={{ color: "red" }}>*</span>
                    </>
                  }
                ></TextField>
              </Grid>

              {/* payment mode */}
              <Grid item xs={3}>
                <FormControl fullWidth error={!!errors.payment_mode}>
                  <InputLabel id="simple-select-label">
                    Payment Mode <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Controller
                    name="payment_mode"
                    control={control}
                    defaultValue="" // Empty value initially
                    rules={{ required: "Payment Mode is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="role-label"
                        label="Payment Mode"
                        defaultValue=""
                      >
                        <MenuItem value="" disabled>
                          Select a Mode
                        </MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="quaterly">Quarterly</MenuItem>
                        <MenuItem value="halfyearly">Half</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.payment_mode && (
                    <FormHelperText>
                      {errors.payment_mode.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* policy issue date */}
              <Grid item xs={3}>
                <TextField
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("policy_issuedate")}
                  fullWidth
                  label="Policy issue Date"
                />
              </Grid>

              {/* policy start date */}
              <Grid item xs={3}>
                <TextField
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("policy_startdate", {
                    required: "Start Date is Required",
                  })}
                  error={!!errors.policy_startdate}
                  helperText={
                    errors.policy_startdate
                      ? errors.policy_startdate.message
                      : ""
                  }
                  fullWidth
                  label={
                    <>
                      Policy Start Date <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
              </Grid>

              {/* policy end date */}
              <Grid item xs={3}>
                <TextField
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("policy_enddate", {
                    required: "End Date is Required",
                  })}
                  error={!!errors.policy_enddate}
                  helperText={
                    errors.policy_enddate ? errors.policy_enddate.message : ""
                  }
                  fullWidth
                  label={
                    <>
                      Policy End Date <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
              </Grid>

              {/* net premium */}
              <Grid item xs={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("netpremium", {
                    required: "Net Premium is Required",
                  })}
                  error={!!errors.netpremium}
                  helperText={
                    errors.netpremium ? errors.netpremium.message : ""
                  }
                  fullWidth
                  label={
                    <>
                      Net Premium <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
              </Grid>

              {/* gross premium */}
              <Grid item xs={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("grosspremium", {
                    required: "Gross Premium is Required",
                  })}
                  error={!!errors.grosspremium}
                  helperText={
                    errors.grosspremium ? errors.grosspremium.message : ""
                  }
                  fullWidth
                  label={
                    <>
                      Gross Premium <span style={{ color: "red" }}>*</span>
                    </>
                  }
                />
              </Grid>

              {/* document */}
              <Grid item xs={12} md={3}>
                <Input
                  accept="application/pdf"
                  id="policy_doc"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={fileChangeHandler}
                  name="policy_doc"
                />
                <label htmlFor="policy_doc" style={{ width: "100%" }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={55}
                    sx={{
                      border: `2px dashed ${
                        policyDocError ? "red" : "#2196f3"
                      }`,
                      borderRadius: "10px",
                    }}
                  >
                    <IconButton component="span">
                      <CloudUploadIcon style={{ color: "black" }} />
                    </IconButton>
                    <Typography variant="body2" color="textSecondary">
                      {policyDoc
                        ? policyDoc?.name.slice(0, 15)
                        : "Upload Policy Copy (pdf)"}
                    </Typography>
                  </Stack>
                </label>

                {policyDocError && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: 1 }}
                  >
                    {policyDocError}
                  </Typography>
                )}
              </Grid>

              {/* button */}
              <Grid item xs={12}>
                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: theme.shape.borderRadius }}
                >
                  {loading ? <><CircularProgress color="inherit" size={16} /> submiting</> : 'submit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </MainCard>
  );
};

export default LifeOfflineForm;
