import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getPolicyReports } from "../../api/adminService";
import { CircularProgress, Typography } from "@mui/material";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { useDispatch } from "react-redux";
import axios from "axios";

export default function CompanyReports() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

const columns = [
  { field: "company_name", headerName: "Quarters", flex:1 },
  { field: "department", headerName: "Department", flex:1 },
  { field: "no_of_policies", headerName: "No of Policies", flex:1 },
  { field: "total_premium", headerName: "Total Premium", flex:1 },
  { field: "total_commision", headerName: "Total Commission", flex:1 },
];

  const getAllPolicyReports = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(`https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/admin/company_reports`);
      if (res.status === 200) {
        const flattenedData = res.data;
        setRows(mapDataWithId(flattenedData));
      } else {
        dispatch(setErrorMessage("Failed to Fetch Data"));
      }
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const mapDataWithId = (data) => data.map((row, id) => ({ ...row, id }));

  React.useEffect(() => {
    getAllPolicyReports();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{mt:3, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
      />
    </Box>
  );
}
