// assets
import {
  IconDashboard,
  IconHelp,
  IconSettings,
  IconUser,
  IconListDetails,
  IconShieldPlus,
  IconExclamationCircle,
  IconHome,
  IconPresentationAnalytics,
  IconCash,
  IconUserEdit,
  IconBusinessplan,
  IconBriefcase,
  IconBuilding,
  IconCreativeCommonsBy,
  IconBellRinging,
  IconRepeat,
  IconZoomMoney,
  IconAntennaBars5,
  IconFiretruck,
  IconBuildingSkyscraper,
  IconBrandCashapp,
  IconPencilPlus,
  IconShieldCheck,
  IconClipboardCopy
} from "@tabler/icons-react";

// constant
const icons = {
  IconDashboard,
  IconHelp,
  IconUser,
  IconListDetails,
  IconShieldPlus,
  IconExclamationCircle,
  IconHome,
  IconPresentationAnalytics,
  IconCash,
  IconUserEdit,
  IconBusinessplan,
  IconBriefcase,
  IconBuilding,
  IconCreativeCommonsBy,
  IconBellRinging,
  IconRepeat,
  IconZoomMoney,
  IconAntennaBars5,
  IconFiretruck,
  IconBuildingSkyscraper,
  IconBrandCashapp,
  IconPencilPlus,
  IconSettings,
  IconShieldCheck,
  IconClipboardCopy
};


const getMenuItems = (profile) => {

  let menuItems = {
    items: [{
      id: 'dashboard',
      type: 'group',
      children: [] 
    }]
  };
  let arr = []
  profile.pages.map((child, i) => {
    let icon = {
      title: "",
      url: "",
      icon: undefined,
      type: 'item'
    }
    if (child.permissions) {
      if (child.permissions.view) {
        icon = { ...icon, title: child.pageName, url: i ? child.path : '/employee', icon: icons[child.icon],id:child.path }
      }
    } else {
      icon = { ...icon, title: child.pageName, url: child.path, icon: icons[child.icon],id:child.path }
    }
    arr.push(icon);
  })
  menuItems.items[0].children = arr;
  return menuItems
}
export default getMenuItems;