import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslateIcon from "@mui/icons-material/Translate";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/action-creators/authAction";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { handleEmployeeLogout } from "../../Redux/admin/action";
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';

const NavbarMain = ({ toggleDrawer }) => {
  const dispatch = useDispatch();
  const pages = ["Insurance Products", "claims", "posp"];
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElProducts, setAnchorElProducts] = React.useState(null);
  const [anchorElSignIn, setAnchorElSignIn] = React.useState(null);
  const userType = localStorage.getItem("userType");
  const isAuthenticated = useSelector(
    (state) => state.auth.data && state.auth.data.token !== ""
  );
  // const isAuthenticated = Object.keys(useSelector((state) => state.auth.data) || {}).length > 0
  
  const pospProfileData = useSelector((state) => state?.posp?.data);
  const EmpData = useSelector((state) => state?.adminReducer?.loginData);
  const customerData = useSelector((state) => state?.customer?.data);
  
  const navigate = useNavigate();
  const firstLetter =
    userType === "customer"
      ? customerData?.customer_name
      : userType === "agent"
      ? pospProfileData?.agent_name
      : EmpData?.name;
  // ................................................................
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // functions for product-menu-dropdown open/close
  const handleOpenProductMenu = (event) => {
    setAnchorElProducts(event.currentTarget);
  };
  const handleCloseProductMenu = () => {
    setAnchorElProducts(null);
  };
  // functions for sign-menu-dropdown open/close
  const handleOpenSignInMenu = (event) => {
    setAnchorElSignIn(event.currentTarget);
  };
  const handleCloseSignInMenu = () => {
    setAnchorElSignIn(null);
  };

  const handleMenuItemClick = (item) => {
    switch (item) {
      case "customer":
        navigate("/customer/sign-in", { state: "customer" });
        break;
      case "posp":
        navigate("/posp");
        break;
      case "employee":
        navigate("/employee");
        break;
      case "company":
        navigate("/company");
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("phone");
    localStorage.removeItem("userType");
    dispatch(logout());
    dispatch (handleEmployeeLogout(undefined));
    navigate("/");
  };

  const dashboardNavigation = () => {
    dispatch({ type: "ISLANDINGPAGETRUE", payload: true });
    if (userType === "customer") {
      navigate("/customer/dashboard");
    } else if (userType === "agent") {
      if (pospProfileData) {
        if (pospProfileData?.status === "Pass") {
          navigate("/posp/dashboard");
        } else {
          navigate("/posp/personaldetails");
        }
      }
    } else if (userType === "employee") {
      navigate("/employee");
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  const clickClaimsBtn = () => {
    if (isAuthenticated && userType === "customer") {
      navigate("/customer/register-claims");
    } else {
      if (isAuthenticated && userType !== "customer") {
        dispatch(logout());
        localStorage.clear();
      }
      navigate("/customer/sign-in", { state: "claim" });
    }
  };

  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");
    contactElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: "primaryDark",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ padding: "8px 16px" }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => dashboardNavigation()}
              sx={{
                display: { xs: "none", md: "flex" },
                cursor : 'pointer'
              }}
            >
              <Stack
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "120px",
                }}
              >
                <img
                  src="https://main.d315p1596xx1q3.amplifyapp.com/static/media/Logo.58fdf7e33453ed97e74a7ed2a1973e2a.svg"
                  alt=""
                />
              </Stack>
            </Typography>
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              onClick={() => dashboardNavigation()}
              sx={{
                ml: 1,
                display: { xs: "flex", md: "none" },
                cursor : 'pointer'
              }}
            >
              <Stack
                sx={{
                  background: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "70px",
                }}
              >
                <img
                  src="https://main.d315p1596xx1q3.amplifyapp.com/static/media/Logo.58fdf7e33453ed97e74a7ed2a1973e2a.svg"
                  alt=""
                />
              </Stack>
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              {/* Insurance Products */}
              {/* <Tooltip title="Insurance Product">
                <Button
                  onClick={handleOpenProductMenu}
                  sx={{ color: "white", display: "block" }}
                >
                  Insurance Products
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: "45px", px: 2 }}
                id="menu-appbar"
                anchorEl={anchorElProducts}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElProducts)}
                onClose={handleCloseProductMenu}
              >
                <MenuItem onClick={handleCloseProductMenu}>
                  <Stack direction={"row"} gap={1}>
                    <DirectionsCarIcon fontSize="small" />
                    <Typography textAlign="center">Car Insurance</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleCloseProductMenu}>
                  <Stack direction={"row"} gap={1}>
                    <CreditScoreIcon fontSize="small" />
                    <Typography textAlign="center">Loan Insurance</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleCloseProductMenu}>
                  <Stack direction={"row"} gap={1}>
                    <ConnectingAirportsIcon fontSize="small" />
                    <Typography textAlign="center">Travel Insurance</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleCloseProductMenu}>
                  <Stack direction={"row"} gap={1}>
                    <HealthAndSafetyIcon fontSize="small" />
                    <Typography textAlign="center">Health Insurance</Typography>
                  </Stack>
                </MenuItem>
              </Menu> */}

              {/* claims */}
              <Button
                sx={{ color: "white", display: "block" }}
                onClick={clickClaimsBtn}
              >
                Claims
              </Button>
              
              {/* contact */}
              <Button sx={{ color: "white", display: "block" }} onClick={scrollToContact}>Contact</Button>
            </Box>
            <Box
              sx={{
                flexGrow: { xs: 1, md: 0 },
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <>
                {isAuthenticated ? (
                  <>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      {/* <Avatar
                      {/* <Avatar
                        alt="Kemy Sharp"
                        src="/static/images/avatar/2.jpg"
                      /> */}
                      <Box
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100%",
                          background: "#90caf9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={"22px"}
                          color={"#1e88e5"}
                        >
                          {firstLetter && firstLetter[0]?.toUpperCase()}
                        </Typography>
                      </Box>
                    </IconButton>
                    <Menu
                      sx={{ mt: "45px", px: 2 }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={dashboardNavigation}>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            {" "}
                            <LeaderboardIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography textAlign="center">
                              Dashboard
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography textAlign="center">Logout</Typography>
                          </ListItemText>
                        </ListItem>
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    <Tooltip title="Signin">
                      <Button
                        startIcon={<LoginRoundedIcon />}
                        onClick={handleOpenSignInMenu}
                        sx={{ color: "white" }}
                      >
                        Signin
                      </Button>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px", px: 2 }}
                      id="menu-appbar"
                      anchorEl={anchorElSignIn}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElSignIn)}
                      onClose={handleCloseSignInMenu}
                    >
                      <MenuItem onClick={() => handleMenuItemClick("customer")}>
                        <Stack direction={"row"} gap={1}>
                          <ManageAccountsIcon fontSize="small" />
                          <Typography textAlign="center">customer</Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick("posp")}>
                        <Stack direction={"row"} gap={1}>
                          <AssignmentIndIcon fontSize="small" />
                          <Typography textAlign="center">posp</Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick("company")}>
                        <Stack direction={"row"} gap={1}>
                          <ApartmentRoundedIcon fontSize="small" />
                          <Typography textAlign="center">Company</Typography>
                        </Stack>
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick("employee")}>
                        <Stack direction={"row"} gap={1}>
                          <AdminPanelSettingsIcon fontSize="small" />
                          <Typography textAlign="center">Employee</Typography>
                        </Stack>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default React.memo(NavbarMain);
